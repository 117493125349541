import React, { HTMLAttributes } from "react";
import Modal from ".";
import { IUserProfileData } from "../../constants/types";
import styled from "styled-components";

interface IUserDetailsModal {
  handleClose: () => void;
  userProfileData: IUserProfileData;
}

interface IAvatarProps extends HTMLAttributes<HTMLDivElement> {
  username?: string | null;
  profileImg?: string;
  size?: number;
}

const Avatar: React.FC<IAvatarProps> = ({ size, style, username, profileImg, ...rest }) => {
  return (
    <StyledAvatar style={{ ...style, width: size, height: size }} size={size} {...rest}>
      {profileImg ? (
        <img src={profileImg} alt="" />
      ) : (
        <span>{username ? username.slice(0, 1) : ""}</span>
      )}
    </StyledAvatar>
  );
};

const StyledAvatar = styled.div<IAvatarProps>`
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--primary);
  display: grid;
  place-items: center;
  position: relative;
  overflow: hidden;

  > span {
    font-size: ${(props) => (props.size ? `${props.size * 0.4}px` : "16px")};
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center !important;
  }
`;

const UserDetailsModal: React.FC<IUserDetailsModal> = ({ handleClose, userProfileData }) => {
  return (
    <Modal isOpen handleClose={handleClose}>
      <StyledContainer>
        <StyledProfile>
          <div className="flex">
            <Avatar
              username={userProfileData.displayName}
              profileImg={userProfileData.profileImg}
              size={60}
            />
            <div className="flex-column ml-20">
              <h5>{userProfileData?.displayName}</h5>
              {/* <p>Qualification</p> */}
            </div>
          </div>
        </StyledProfile>
        <StyledTagContainer>
          {userProfileData.interests.length ? (
            <>
              <label>Interests</label>
              <StyledTagList>
                {userProfileData.interests.map((int, i) => (
                  <span key={i.toString()} className="tag-variant-one">
                    {int}
                  </span>
                ))}
              </StyledTagList>
            </>
          ) : null}
        </StyledTagContainer>
        <StyledTagContainer>
          {userProfileData.issues.length ? (
            <>
              <label>Issues Currently Facing</label>
              <StyledTagList>
                {userProfileData.issues.map((issue, i) => (
                  <span key={i.toString()} className="tag-variant-one">
                    {issue}
                  </span>
                ))}
              </StyledTagList>
            </>
          ) : null}
        </StyledTagContainer>
        <StyledTagContainer>
          {userProfileData.goals.length ? (
            <>
              <label>Goals</label>
              <StyledTagList>
                {userProfileData.goals.map((goal, i) => (
                  <span key={i.toString()} className="tag-variant-one">
                    {goal}
                  </span>
                ))}
              </StyledTagList>
            </>
          ) : null}
        </StyledTagContainer>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  max-height: 75vh;
`;

const StyledProfile = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
`;

const StyledTagContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    display: block;
    color: var(--text-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
`;

const StyledTagList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;

  .tag-variant-one {
    border-radius: 30px;
    border: 1.5px solid #b4b4b3;
    background: var(--white, #fff);
    color: var(--text-primary);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 8px;
    text-transform: capitalize;
  }
`;

export default UserDetailsModal;
