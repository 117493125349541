import React, { useEffect, useState } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  updatePassword,
  AuthErrorCodes,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

import {
  StyledAuthHeader,
  StyledDiv,
  StyledAuthHeaderFlex,
  StyledContainer,
  StyledAuthForm,
} from "./Signup.styled";

import logo from "../../../assets/logo/logo.png";
import AuthSlider from "../AuthSlider";
import { auth, db } from "../../../utils/firebase";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { userStore } from "../../../store/userStore";
import {
  TUTOR_ACCOUNTS_COLLECTION_NAME,
  TUTOR_COLLECTION_NAME,
} from "../../../constants/databaseTypes";
import { uploadProfileImage } from "../../../services/storageService";
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";

interface IStep2State {
  username: string;
  description: string;
  gender: string;
  profileImg: string;
  interests: string[];
  specialization: string[];
}

export const step1State = { password: "", confirmPassword: "" };
export const step2State: IStep2State = {
  username: "",
  description: "",
  gender: "",
  profileImg: "",
  interests: [],
  specialization: [],
};
export const step3State: {
  aadharNo: string;
  bankAccountNo: string;
  ifscCode: string;
} = {
  aadharNo: "",
  bankAccountNo: "",
  ifscCode: "",
};

const Signup: React.FC<{ newUser?: boolean }> = ({ newUser }) => {
  const [emailError, setEmailError] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(!!newUser ? 1 : 1);
  const [formData, setFormData] = useState(
    Object.assign({}, step1State, step2State, step3State)
  );
  const user = userStore((store) => store.user);
  const [profileImg, setProfileImg] = useState<{
    file: File;
    url: string;
  } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!emailError) return;

    const interval = setTimeout(() => {
      setEmailError(null);
    }, 5000);

    return () => {
      clearTimeout(interval);
    };
  }, [emailError]);

  const handleSubmitStep1 = async (values: typeof step1State) => {
    try {
      if (!user) return;

      await updatePassword(user, values.password);
      setCurrentStep(2);
    } catch (error) {
      console.log(error);
      if (error instanceof FirebaseError) {
        if (error.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
          await auth.signOut();
          navigate("/login");
        }
      }
    }
  };

  const handleSubmitStep2 = (values: typeof step2State) => {
    setFormData((f) => ({ ...f, ...values }));
    setCurrentStep(3);
  };

  const handleSubmitStep3 = async (values: typeof step3State) => {
    const data = { ...formData, ...values };
    try {
      if (!!newUser && user) {
        const userDocRef = doc(db, TUTOR_COLLECTION_NAME, user.uid);
        const tutorAccountDocRef = doc(
          db,
          TUTOR_ACCOUNTS_COLLECTION_NAME,
          user.uid
        );
        await updateProfile(user, { displayName: data.username });
        let profileUrl = "";
        if (profileImg) {
          profileUrl = await uploadProfileImage(user.uid, profileImg.file);
        }
        await setDoc(userDocRef, {
          email: user.email,
          username: data.username,
          description: data.description,
          profileImg: profileUrl,
          gender: data.gender,
          specialization: data.specialization,
          interests: data.interests,
        });
        await setDoc(tutorAccountDocRef, {
          bankAccountNo: data.bankAccountNo,
          ifscCode: data.ifscCode,
          aadharNo: data.aadharNo,
        });

        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleSignin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const data = await signInWithPopup(auth, provider);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledDiv>
      <StyledContainer>
        <StyledAuthHeader>
          <img src={logo} alt="logo" className="logo" />
          <StyledAuthHeaderFlex>
            <p className={currentStep === 1 ? "active" : "inactive"}>Step 1</p>
            <span>-</span>
            <p
              className={
                currentStep === 2
                  ? "active"
                  : currentStep === 3
                  ? "inactive"
                  : undefined
              }
            >
              Step 2
            </p>
            <span>-</span>
            <p className={currentStep === 3 ? "active" : undefined}>Step 3</p>
          </StyledAuthHeaderFlex>
        </StyledAuthHeader>
        <StyledAuthForm>
          {currentStep === 1 && (
            <Step1
              handleSubmit={handleSubmitStep1}
              handleGoogleSignin={handleGoogleSignin}
              emailError={emailError}
            />
          )}
          {currentStep === 2 && (
            <Step2
              handleSubmit={handleSubmitStep2}
              profileImg={profileImg}
              setProfileImg={setProfileImg}
            />
          )}
          {currentStep === 3 && <Step3 handleSubmit={handleSubmitStep3} />}
        </StyledAuthForm>
      </StyledContainer>
      <p className="text-secondary">2023 Talkgram, All Rights Reserved</p>
      <AuthSlider />
    </StyledDiv>
  );
};

export default Signup;
