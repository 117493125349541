import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../Button";
import SectionHeader from "../SectionHeader";
import Tab from "../Tab";
import { useGetDetailedOverview } from "../../hooks/useGetDetailedOverview";
import { TBookingSessionStatus } from "../../constants/types";
import { customFormat } from "../../constants/formatDate";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calculateTimeDifference } from "../../constants/formatter";
import dayjs from "dayjs";
import { userStore } from "../../store/userStore";

const DetailedOverview = () => {
  const tutor = userStore((store) => store.user);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [activeTab, setActiveTab] = useState<TBookingSessionStatus>("COMPLETED");
  const { data, loading } = useGetDetailedOverview({
    status: activeTab,
    selectedDate: selectedDate,
  });
  const [paymentStatus, setPaymentStatus] = useState<"Pending" | "Completed">("Pending");

  useEffect(() => {
    const checkPayment = async () => {
      const paymentsRef = collection(db, "Payments");

      // Query the collection to check if a document with the given tutorId, currentMonth, and currentYear exists
      const q = query(
        paymentsRef,
        where("tutorId", "==", tutor?.uid),
        where("paidMonth", "==", dayjs(selectedDate).format("YYYY-MM"))
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Document found, payment is completed
        setPaymentStatus("Completed");
      } else setPaymentStatus("Pending");
    };

    if (tutor?.uid) {
      checkPayment();
    }
  }, [tutor, selectedDate]);

  return (
    <div>
      <SectionHeader title="Detailed Overview" description="Select a range of dates" />

      <div className="flex-between  mb-24">
        <div className="block-left">
          <StyledDatePicker>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              showMonthYearPicker
              dateFormat="MMM, yyyy"
              maxDate={new Date()}
              minDate={new Date("2023-10-01")}
            />
          </StyledDatePicker>
          {/* <Button>Search</Button> */}
        </div>
        <Tab
          lists={[
            { label: "Completed", value: "COMPLETED" },
            { label: "Missed", value: "TUTOR_MISSED" },
            { label: "Cancelled", value: "TUTOR_CANCELLED" },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab as any}
        />
      </div>
      <StyledSummaryHold>
        <StyledSummary>
          <div>
            <h5>Summary</h5>
            <table>
              <tbody>
                <tr>
                  <td>Total Sessions</td>
                  <td align="right">
                    <strong>{data?.totalSessions ?? 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Total Subscription Sessions</td>
                  <td align="right">
                    <strong>{data?.totalSubscriptionSessions ?? 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Total Demo Sessions</td>
                  <td align="right">
                    <strong>{data?.totalDemoSessions ?? 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Total Penalties</td>
                  <td align="right">
                    <strong>{data?.totalPenalities ?? 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Total Earnings</td>
                  <td align="right">
                    <strong>{data?.totalEarnings ?? 0}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Net Total</td>
                  <td align="right">
                    <strong>{data?.netTotal ?? 0}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </StyledSummary>
        <div style={{ display: "flex", alignItems: "center" }}>
          Payment Status: &nbsp;
          <h5 style={{ color: paymentStatus === "Completed" ? "var(--success)" : "#f7941f" }}>
            {paymentStatus}
          </h5>
        </div>
      </StyledSummaryHold>
      <StyledTableWrapper status={activeTab}>
        <table>
          <thead>
            <tr>
              <th>Session Date</th>
              <th>Session Start Time</th>
              <th>Session End Time</th>
              <th>Session Joining Time</th>
              <th>Duration</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6}>Fetching...</td>
              </tr>
            ) : !data?.sessionData.length ? (
              <tr>
                <td colSpan={6}>No Data Found</td>
              </tr>
            ) : (
              data?.sessionData.map((sessionData, index) => (
                <tr key={index.toString()}>
                  <td>{customFormat(sessionData.startTime.toDate(), "DD/MM/YYYY")}</td>
                  <td>{customFormat(sessionData.startTime.toDate(), "hh:mm a")}</td>
                  <td>{customFormat(sessionData.endTime.toDate(), "hh:mm a")}</td>
                  <td>
                    {sessionData?.timelogs
                      ?.filter((f) => f.participantId === sessionData.tutor)
                      ?.slice(0, 1)
                      ?.map((m) => customFormat(sessionData.startTime.toDate(), "hh:mm a"))}
                  </td>
                  <td>
                    {sessionData?.timelogs
                      ?.filter((f) => f.participantId === sessionData.tutor)
                      ?.slice(0, 1)
                      ?.map((m) => calculateTimeDifference(m.overallDuration))}
                  </td>
                  <td>{sessionData.status === "MISSED" ? "USER MISSED" : sessionData.status}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </StyledTableWrapper>
    </div>
  );
};

const StyledTableWrapper = styled.div<{ status: TBookingSessionStatus }>`
  width: 100%;
  overflow-x: auto;
  border-left: 0.5px solid var(--gray-3, #787878);
  border-right: 0.5px solid var(--gray-3, #787878);

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 25px;
      border-top: 0.5px solid var(--gray-3, #787878);
      border-bottom: 0.5px solid var(--gray-3, #787878);
      text-align: center;
      white-space: nowrap;
    }

    td {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 25px;
      border-top: 0.5px solid var(--gray-3, #787878);
      border-bottom: 0.5px solid var(--gray-3, #787878);
      text-align: center;
      background-color: ${(props) =>
        props.status === "COMPLETED"
          ? "var(--completed)"
          : props.status === "TUTOR_MISSED"
          ? "var(--missed)"
          : "var(--cancelled)"};
      white-space: nowrap;
    }
  }
`;

const StyledSummaryHold = styled.div`
  display: flex;
  margin: 0 1rem;
  justify-content: space-between;
`;

const StyledSummary = styled.div`
  margin-bottom: 30px;

  > div {
    max-width: fit-content;
    width: 100%;
    border-radius: 8px;
    border: 2px solid var(--gray-1, #ecf0ef);
    padding: 15px 24px;

    h5 {
      color: var(--primary-1, #f7941f);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 15px;
    }

    table {
      border-collapse: collapse;
      td {
        border: 1px solid var(--gray-1, #ecf0ef);
        padding: 5px;
      }
    }
  }
`;

const StyledDatePicker = styled.div`
  .react-datepicker-wrapper {
    input {
      border-radius: 8px;
      border: 1px solid #ede7df;
      color: #2e2d2d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      padding: 10px 20px;
      outline-color: rgba(247, 148, 31, 0.2);
    }
  }
`;

export default DetailedOverview;
