import React, { useCallback, useEffect, useState } from "react";
import { userStore } from "../../../store/userStore";
import { getTutorAccountDetails } from "../../../services/tutorService";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import { ITutorAccountDetails } from "../../../constants/types";
import AccountInfo from "./AccountInfo";

const MyBankDetails = () => {
  const user = userStore((store) => store.user);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ITutorAccountDetails | null>(null);

  const handleGetData = useCallback(async () => {
    if (!user) return;
    try {
      setLoading(true);
      setData((await getTutorAccountDetails(user.uid)) as any);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  if (loading) {
    return (
      <StyledContainer>
        <CircularProgress color="primary" />
      </StyledContainer>
    );
  }

  if (!data || !user) {
    return (
      <StyledContainer>
        <p>No data found</p>
      </StyledContainer>
    );
  }

  return <AccountInfo tutorId={user.uid} {...data} setData={setData} />;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  min-height: 40vh;
`;

export default MyBankDetails;
