import React from "react";
import YourEarnings from "../../components/YourEarnings";

const EarningsPage = () => {
  return (
    <div className="page-container">
      <YourEarnings />
    </div>
  );
};

export default EarningsPage;
