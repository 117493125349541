import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import Calendar from "react-calendar";

import SectionHeader from "../../components/SectionHeader";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/arrow_down.svg";
import { Button } from "../../components";
import { db } from "../../utils/firebase";
import { BOOKINGS_COLLECTION_NAME, IBookingSession } from "../../constants/types";
import { userStore } from "../../store/userStore";
import AttendanceCard from "../../components/Card/AttendanceCard";
import { customFormat } from "../../constants/formatDate";
import { useOnClickOutside } from "usehooks-ts";
import { getFilteredSessions } from "../../services/bookSessionService";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const AttendancePage = () => {
  const user = userStore((store) => store.user);
  const [sessions, setSessions] = useState<IBookingSession[]>([]);
  const [loading, setLoading] = useState(true);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [toDropdown, setToDropdown] = useState(false);
  const [fromDate, onChangeFromDate] = useState<Value>(new Date());
  const [toDate, onChangeToDate] = useState<Value>(new Date());
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);

  const handleClickOutside = () => {
    setFromDropdown(false);
    setToDropdown(false);
  };

  useOnClickOutside(fromDateRef, handleClickOutside);
  useOnClickOutside(toDateRef, handleClickOutside);

  useEffect(() => {
    if (!user) return;
    const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
    const q = query(
      colRef,
      where("tutor", "==", user.uid),
      where("startTime", "<=", new Date()),
      where("status", "in", ["UPCOMING", "COMPLETED"]),
      orderBy("startTime", "desc"),
      limit(25)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const slots: any[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          slots.push({
            id: doc.id,
            ...data,
          });
        });
        setSessions(slots);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching upcoming sessions:", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  console.log(sessions);

  const handleSearch = async () => {
    try {
      if (!user) return;
      setLoading(true);
      const data = await getFilteredSessions(user?.uid, fromDate as any, toDate as any);
      setSessions(data);
      setLoading(false);
    } catch (error) {}
  };

  const tableHeader = (
    <thead>
      <tr>
        <th>Learner Name</th>
        <th>Meeting ID</th>
        <th>Date</th>
        <th>Time</th>
        <th>Attendance</th>
        <th>Joining Time</th>
        <th>Duration</th>
        <th>Raise Dispute</th>
      </tr>
    </thead>
  );

  return (
    <div className="attendance-page page-container">
      <div className="mx pad">
        <StyledContainer>
          <SectionHeader title="Session Attendance" />
          <StyledFilterDiv>
            <StyledDateDropdown>
              <StyledDateDropdownHeader onClick={() => setFromDropdown((f) => !f)}>
                <span>{customFormat((fromDate as any) || new Date(), "DD/MM/YYYY")}</span>
                <DownArrowIcon />
              </StyledDateDropdownHeader>
              {fromDropdown && (
                <StyledDateCalendar ref={fromDateRef}>
                  <Calendar maxDate={new Date()} value={fromDate} onChange={onChangeFromDate} />
                </StyledDateCalendar>
              )}
            </StyledDateDropdown>
            <span>To</span>
            <StyledDateDropdown>
              <StyledDateDropdownHeader onClick={() => setToDropdown((f) => !f)}>
                <span>{customFormat((toDate as any) || new Date(), "DD/MM/YYYY")}</span>
                <DownArrowIcon />
              </StyledDateDropdownHeader>
              {toDropdown && (
                <StyledDateCalendar ref={toDateRef}>
                  <Calendar
                    minDate={fromDate as any}
                    maxDate={new Date()}
                    value={toDate}
                    onChange={onChangeToDate}
                  />
                </StyledDateCalendar>
              )}
            </StyledDateDropdown>
            <Button disabled={loading} onClick={() => handleSearch()}>
              Search
            </Button>
          </StyledFilterDiv>
          <StyledTableWrapper>
            {loading ? (
              <>
                <table>
                  {tableHeader}
                  <tbody></tbody>
                </table>
                <div className="table-footer">Loading...</div>
              </>
            ) : !sessions.length ? (
              <>
                <table>
                  {tableHeader}
                  <tbody></tbody>
                </table>
                <div className="table-footer">No sessions found</div>
              </>
            ) : (
              <table>
                {tableHeader}
                <tbody>
                  {sessions.map((sessionDetails) => (
                    <AttendanceCard key={sessionDetails.id} {...sessionDetails} />
                  ))}
                </tbody>
              </table>
            )}
          </StyledTableWrapper>
        </StyledContainer>
      </div>
    </div>
  );
};

const StyledContainer = styled.div``;

const StyledFilterDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
`;

const StyledDateDropdown = styled.div`
  position: relative;
`;

const StyledTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  border-left: 0.5px solid var(--gray-3, #787878);
  border-right: 0.5px solid var(--gray-3, #787878);

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 25px;
      border-top: 0.5px solid var(--gray-3, #787878);
      border-bottom: 0.5px solid var(--gray-3, #787878);
      text-align: center;
      white-space: nowrap;
    }

    tr {
      td {
        background-color: transparent;
      }

      &.tag-completed {
        td {
          background-color: #e4fce3;
        }
      }
      &.tag-missed {
        td {
          background-color: #fff5dc;
        }
      }
      &.tag-cancelled {
        td {
          background-color: #ffd8dc;
        }
      }
    }

    td {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 25px;
      border-top: 0.5px solid var(--gray-3, #787878);
      border-bottom: 0.5px solid var(--gray-3, #787878);
      text-align: center;
      background-color: #e4fce3;
      white-space: nowrap;
    }
  }

  .table-footer {
    text-align: center;
    border-bottom: 0.5px solid var(--gray-3, #787878);
    padding: 30px;
    line-height: 300px;
  }
`;

const StyledDateDropdownHeader = styled.div`
  border-radius: 9px;
  border: 1px solid rgba(204, 204, 204, 0.8);
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
`;

const StyledDateCalendar = styled.div`
  position: absolute;
  top: 100%;
  left: 0;

  > .react-calendar {
    border-radius: 10px;
    overflow: hidden;
  }
`;

export default AttendancePage;
