import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SectionHeader from "../../components/SectionHeader";
import SessionCard from "../../components/SessionCard";
import TutorSessionChart from "../../components/TutorSessionChart";
import TutorStats from "../../components/TutorStats";
import { userStore } from "../../store/userStore";
import { BOOKINGS_COLLECTION_NAME, IBookingSession } from "../../constants/types";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase";
import NoSessionIllustration from "../../assets/images/no_sessions.svg";

const Home: React.FC = () => {
  const user = userStore((store) => store.user);
  const [sessions, setSessions] = useState<IBookingSession[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
    const q = query(
      colRef,
      where("tutor", "==", user.uid),
      where("status", "==", "UPCOMING"),
      where("endTime", ">=", new Date()),
      orderBy("endTime", "asc"),
      limit(1)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const slots: IBookingSession[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          slots.push({
            id: doc.id,
            ...(data as Omit<IBookingSession, "id">),
          });
        });
        setSessions(slots);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching upcoming sessions:", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <div className="home page-container">
      <div className="mx pad">
        <StyledGridContainer>
          <StyledBlockOne>
            <SectionHeader
              title={`Welcome ${user?.displayName}`}
              description="To teach is to touch a life forever."
            />
            <TutorSessionChart />
            <StyledUpComingSessionContainer>
              <div className="flex-between mb-20">
                <h5 className="section-title">Your Upcoming Sessions</h5>
                <StyledViewAllLink to="/sessions">View all</StyledViewAllLink>
              </div>
              {loading ? (
                <StyledNoSession>
                  <p>Getting your sessions...</p>
                </StyledNoSession>
              ) : sessions.length ? (
                sessions.map((sessionDetails) => (
                  <SessionCard key={sessionDetails.id} type="upcoming" {...sessionDetails} />
                ))
              ) : (
                <StyledNoSession>
                  <img src={NoSessionIllustration} alt="no session illustration" />
                  <p>You do not have any Upcoming Sessions</p>
                </StyledNoSession>
              )}
            </StyledUpComingSessionContainer>
          </StyledBlockOne>
          <StyledBlockTwo>
            <TutorStats />
          </StyledBlockTwo>
        </StyledGridContainer>
      </div>
    </div>
  );
};

const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  gap: 65px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const StyledBlockOne = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledBlockTwo = styled.div``;

const StyledUpComingSessionContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const StyledViewAllLink = styled(Link)`
  color: var(--primary-1, #f7941f);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledNoSession = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
`;

export default Home;
