import { Timestamp } from "firebase/firestore";

export enum ICategory {
  TUTOR_TALK = "TUTOR_TALK",
  CURRICULUM = "CURRICULUM",
}

export enum IExperience {
  GREAT = "GREAT",
  AVERAGE = "AVERAGE",
  CAN_BE_IMPROVED = "CAN BE IMPROVED",
}

export enum ISession {
  UPCOMING = "upcoming",
  PREVIOUS = "previous",
  MISSED = "missed",
  CANCELLED = "cancelled",
  USER_MISSED = "user-missed",
}

export enum ITransactionStatus {
  SUCCESSFUL = "successful",
  PENDING = "pending",
  INFO = "info",
  FAILED = "failed",
}

export enum IFluencyFeedback {
  LONG_PAUSES = "long pauses",
  INCOMPLETE_SENTENCES = "incomplete sentences",
  FILLER_SOUNDS = "filler sounds",
}

export enum IPronunciationFeedback {
  SH_SOUND = "Sh sound",
  H_SOUND = "H sound",
  DANDTH_SOUND = "D and TH sounds",
  FANDP_SOUND = "F and P sounds",
  NO_ERRORS = "no errors",
}

export interface ITutorProfileData {
  specialization: string[];
  gender: string;
  interests: string[];
  username: string;
  profileImg: string;
  description: string;
  completedSession: number | undefined;
  totalRatings?: number;
  totalRatingsCount?: number;
}

export type TDropdownList = {
  name: string;
  value: string;
};

export interface ISlot {
  id: string;
  startTime: Date;
  endTime: Date;
  tutors: { tutorId: string; isReserved: boolean; isDemoClass?: boolean }[];
}

export enum TBookingStatus {
  UPCOMING = "UPCOMING",
  TUTOR_MISSED = "TUTOR_MISSED",
  TUTOR_CANCELLED = "TUTOR_CANCELLED",
  USER_CANCELLED = "USER_CANCELLED",
  MISSED = "MISSED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  USER_MISSED_PREINFORMED = "USER_MISSED_PREINFORMED",
}

export type TBookingSessionStatus =
  | "UPCOMING"
  | "TUTOR_MISSED"
  | "TUTOR_CANCELLED"
  | "USER_CANCELLED"
  | "MISSED"
  | "CANCELLED"
  | "COMPLETED"
  | "USER_MISSED_PREINFORMED";

export interface ITutorFeedback {
  status: string;
  fluencyRating: number;
  pronounciationRating: number;
  vocabulary: string;
  grammer: {
    tenses: string;
    articlesAndPrepositions: string;
    subjectVerb: string;
    pronounUsage: string;
    other: string;
    mostErrors: string;
  };
  skills: {
    confidence: number;
    passion: number;
    listeningComprehension: number;
    conversationBuilding: number;
  };
  suggestions: {
    month: number;
    sessionsPerWeek: number;
  };
}

export interface INewTutorFeedback {
  status: string;
  fluency: {
    rating: number;
    feedback: string;
  };
  pronunciation: {
    rating: number;
    feedback: string;
  };
  vocabulary: {
    general: string;
    range: string;
    wordChoicePrecision: string;
  };
  grammar: {
    tenses: string;
    mostErrors: string;
    articlesAndPrepositions: string;
    subjectVerb: string;
    pronounUsage: string;
    other: string;
  };
  skills: {
    confidence: number;
    passion: number;
    listeningComprehension: number;
    conversationBuilding: number;
  };
  suggestions: {
    month: number;
    sessionsPerWeek: number;
  };
  generalFeedback: string;
  homework: string;
}

export interface Timelog {
  start: Date;
  end: Date;
}

export interface IBookingSession {
  id: string;
  status: TBookingSessionStatus;
  startTime: Timestamp;
  endTime: Timestamp;
  slotId: string;
  topic:
    | string
    | {
        title: string;
        pointers: {
          pointer: string;
          questions: string[];
        };
      };
  topicInfo?: {
    title: string;
    category: string;
  };
  description: string;
  user: string;
  tutor: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  userFeedback?: {
    experience: string;
    feedback: string;
    rating: number;
  };
  feedbackFromTutor?: INewTutorFeedback;
  meetingId?: string;
  subscriptionId: string;
  currentSession?: number;
  timelogs:
    | {
        startTime: Date;
        endTime: Date;
        overallDuration: number;
        participantId: string;
        timelog: Timelog[];
      }[]
    | null;
  demoClass?: boolean;
  isLastSession?: boolean;
}

/* -------DATABASE COLLECTION NAMES--------------- */
export const TUTOR_COLLECTION_NAME = "Tutors";
export const USER_COLLECTION_NAME = "Users";
export const TUTOR_ACCOUNTS_COLLECTION_NAME = "TutorsAccountDetails";
export const TIME_SLOTS_COLLECTION_NAME = "Slots";
export const BOOKINGS_COLLECTION_NAME = "Bookings";
export const SUBSCRIPTION_COLLECTION_NAME = "Subscriptions";
export const BLOCK_USER_COLLECTION_NAME = "BlockUsers";
/* ----------------------------------------------- */

export interface IUserProfileData {
  designation: string;
  gender: string;
  goals: string[];
  interests: string[];
  issues: string[];
  email: string;
  displayName: string;
  isNewUser: boolean;
  demoClassBooked: boolean;
  currentSubscriptionId?: string;
  profileImg: string;
}

export interface ISubscriptionDB {
  id: string;
  endDate: Timestamp;
  createdAt: Timestamp;
  demoClass?: boolean;
  plan: string;
  status: "SUCCESS" | "PENDING" | "FAILED";
  startDate: Timestamp;
  noOfSession: number;
  totalPrice: number;
  updateAt: Timestamp;
  user: string;
  completedSession?: number;
  cancelledSession?: number;
  backlogSession?: number;
  bookedSession?: number;
  missedSession?: number;
  offer: number;
}

export interface IDispute {
  id: string;
  dateOfIssue: Timestamp;
  description: string;
  issue: string;
  sessionId: string;
  solution: string;
  status: "pending" | "resolved";
  userId: string;
}

export interface ITopic {
  title: string;
  category: string;
  pointers: {
    pointer: string;
    questions: string[];
  }[];
  vocabulary: {
    word: string;
    speech: string;
    meaning: string;
  }[][];
}

export interface ITutorAccountDetails {
  aadharNo: string;
  bankAccountNo: string;
  ifscCode: string;
  id: string;
  updatedAt?: string;
  verified?: boolean;
}

export interface ITutorFee {
  demoClassTutorFee: number;
  subscriptionClassTutorFee: number;
}
