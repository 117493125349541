import { create } from "zustand";

type State = {
  demoClassTutorFee: number;
  subscriptionClassTutorFee: number;
  isFetching: boolean;
};

type Action = {
  updateFee: (data: Partial<State>) => void;
};

export const useGeneralStore = create<State & Action>((set) => ({
  demoClassTutorFee: 0,
  subscriptionClassTutorFee: 0,
  isFetching: true,
  updateFee: (data) => set(() => ({ isFetching: false, ...data })),
}));
