import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { startOfDay } from "date-fns";
import dayjs from "dayjs";

import { Button } from "../../components";
import BookingCard from "../../components/Card/BookingCard";
import SectionHeader from "../../components/SectionHeader";
import { getCurrent7Days } from "../../constants/formatter";
import { userStore } from "../../store/userStore";
import { db } from "../../utils/firebase";
import { TIME_SLOTS_COLLECTION_NAME } from "../../constants/databaseTypes";
import { ISlot } from "../../constants/types";

const BookingsPage: React.FC = () => {
  const user = userStore((store) => store.user);
  const [userBookedSlots, setUserBookedSlots] = useState<ISlot[]>([]);
  const [currentWeekDays, setCurrentWeekDays] = useState(getCurrent7Days());
  const [isCurrentWeek, setIsCurrentWeek] = useState(true);

  const handleGetBookedSlotsByUser = useCallback(() => {
    if (!user) return;

    const timeSlotsRef = collection(db, TIME_SLOTS_COLLECTION_NAME);
    const q = query(timeSlotsRef, where("startTime", ">=", startOfDay(new Date())));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bookedSlots: any[] = [];

      querySnapshot.forEach((doc) => {
        bookedSlots.push({ id: doc.id, ...doc.data() });
      });

      setUserBookedSlots(
        bookedSlots
          .filter((f) => f.tutors.some((s: any) => s.tutorId === user.uid))
          .map((b) => ({
            ...b,
            startTime: b.startTime.toDate(),
            endTime: b.endTime.toDate(),
          }))
      );
    });

    // Return a cleanup function to unsubscribe when component unmounts or when the dependency changes
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    handleGetBookedSlotsByUser();
  }, [handleGetBookedSlotsByUser]);

  return (
    <div className="bookings-page page-container">
      <div className="mx pad">
        <StyledBookingsPageContainer>
          <div className="flex-between">
            <SectionHeader
              title="Set Up Your Working Hours for English Tutor Talks"
              description="You can setup a schedule for the week. This schedule will be repeated for the following weeks. You can edit it whenever you want to. "
            />
            <Button
              onClick={() => {
                if (isCurrentWeek) {
                  setCurrentWeekDays(getCurrent7Days(dayjs().add(7, "days").toDate()));
                  setIsCurrentWeek(false);
                } else {
                  setCurrentWeekDays(getCurrent7Days());
                  setIsCurrentWeek(true);
                }
              }}
            >
              {isCurrentWeek ? "Book Slots For Next Week" : "Go back"}
            </Button>
          </div>
          <StyledTableWrapper>
            {currentWeekDays.map((date, index) => {
              // Filter userBookedSlots to match with the whole day
              const filteredSlots = userBookedSlots.filter((slot) => {
                const slotDate = new Date(slot.startTime);

                return (
                  slotDate.getFullYear() === date.getFullYear() &&
                  slotDate.getMonth() === date.getMonth() &&
                  slotDate.getDate() === date.getDate()
                );
              });
              return (
                <BookingCard
                  key={index.toString()}
                  date={date}
                  bookedSlots={filteredSlots}
                  refetch={handleGetBookedSlotsByUser}
                />
              );
            })}
          </StyledTableWrapper>
        </StyledBookingsPageContainer>
      </div>
    </div>
  );
};

const StyledBookingsPageContainer = styled.div`
  .flex-between {
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

const StyledTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 700px;
  width: 100%;
  margin-top: 20px;
`;

export default BookingsPage;
