import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  increment,
  query,
  runTransaction,
  Timestamp,
  where,
} from "firebase/firestore";

import {
  BOOKINGS_COLLECTION_NAME,
  ISubscriptionDB,
  SUBSCRIPTION_COLLECTION_NAME,
  TIME_SLOTS_COLLECTION_NAME,
  USER_COLLECTION_NAME,
} from "../constants/types";
import { IBookingSession } from "../constants/types";
import { db } from "../utils/firebase";
import { endOfDay, startOfDay } from "date-fns";

export const createBookSessionDoc = async (data: DocumentData) => {
  const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
  return await addDoc(colRef, data);
};

export const updateBookSessionDoc = async (id: string, data: DocumentData) => {
  await runTransaction(db, async (transaction) => {
    const docRef = doc(db, BOOKINGS_COLLECTION_NAME, id);

    const bookingSessionData = await transaction.get(docRef);
    if (!bookingSessionData.exists()) throw new Error("Booking session not found");

    const sessionData = bookingSessionData.data() as IBookingSession;

    const subscriptionDocRef = doc(db, SUBSCRIPTION_COLLECTION_NAME, sessionData.subscriptionId);
    const subscriptionDocData = await transaction.get(subscriptionDocRef);

    if (!subscriptionDocData.exists()) throw new Error("Subscription data not found");

    const subscriptionData = subscriptionDocData.data() as ISubscriptionDB;

    transaction.update(docRef, { ...data, updatedAt: Timestamp.now() });

    if (data.status === "COMPLETED") subscriptionData.completedSession = increment(1) as any;
    if (data.status === "MISSED") subscriptionData.missedSession = increment(1) as any;
    if (data.status === "USER_MISSED_PREINFORMED") {
      // subscriptionData.missedSession = increment(1);
      subscriptionData.backlogSession = increment(1) as any;
    }

    if (subscriptionData.bookedSession === subscriptionData.noOfSession) {
      transaction.update(subscriptionDocRef, {
        ...subscriptionData,
        allSessionsCompleted: true,
      });
    } else {
      transaction.update(subscriptionDocRef, {
        ...subscriptionData,
      });
    }
  });
};

export const deleteBookSessionDoc = async (id: string) => {
  await runTransaction(db, async (transaction) => {
    const docRef = doc(db, BOOKINGS_COLLECTION_NAME, id);
    const bookSessionDoc = await transaction.get(docRef);
    if (!bookSessionDoc.exists()) throw new Error("No session found");
    const bookSessionData = bookSessionDoc.data() as IBookingSession;

    const timeSlotDocRef = doc(db, TIME_SLOTS_COLLECTION_NAME, bookSessionData.slotId);
    const timeSlotDoc = await transaction.get(timeSlotDocRef);
    if (!timeSlotDoc.exists()) throw new Error("No slots found");
    const timeSlotData = timeSlotDoc.data();

    const subscriptionDocRef = doc(
      db,
      SUBSCRIPTION_COLLECTION_NAME,
      bookSessionData.subscriptionId
    );
    const subscriptionDoc = await transaction.get(subscriptionDocRef);
    if (!subscriptionDoc.exists()) throw new Error("No subscription found");

    const updatedArray = timeSlotData.tutors.map((tutor: any) => {
      if (tutor.tutorId === bookSessionData.tutor) {
        return { ...tutor, isReserved: false };
      }
      return tutor;
    });

    transaction.update(timeSlotDocRef, {
      tutors: updatedArray,
    });
    transaction.update(docRef, {
      status: "TUTOR_CANCELLED",
      updateAt: Timestamp.now(),
    });
    transaction.update(subscriptionDocRef, {
      backlogSession: subscriptionDoc.data()?.demoClass === true ? 1 : increment(1),
    });
  });
};
export const getBookingSessionDoc = async (docId: string) => {
  const docRef = doc(db, BOOKINGS_COLLECTION_NAME, docId);
  const docResult = await getDoc(docRef);
  const docData = docResult.data() as Omit<IBookingSession, "id">;

  if (!docData) return null;

  return { id: docResult.id, ...docData };
};

export const getFilteredSessions = async (tutorId: string, startDate: Date, endDate: Date) => {
  const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
  const q = query(
    colRef,
    where("tutor", "==", tutorId),
    where("startTime", ">=", startOfDay(startDate)),
    where("startTime", "<=", endOfDay(endDate))
  );

  const datum = await getDocs(q);

  const slots: any[] = [];

  datum.forEach((doc) => {
    const data = doc.data();
    slots.push({
      id: doc.id,
      ...data,
    });
  });

  return slots;
};
