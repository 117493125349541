import { onAuthStateChanged, User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { userStore } from "../../store/userStore";
import { auth, db } from "../../utils/firebase";
import { TUTOR_ACCOUNTS_COLLECTION_NAME } from "../../constants/databaseTypes";

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const updateUser = userStore((state) => state.updateUser);
  const updateFetching = userStore((state) => state.updateFetching);

  const getUserData = useCallback(
    async (user: User) => {
      try {
        const userDocRef = doc(db, TUTOR_ACCOUNTS_COLLECTION_NAME, user.uid);
        const userSnapshot = await getDoc(userDocRef);

        setTimeout(() => {
          updateFetching(false);
        }, 0);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          console.log(userData);
          navigate("/");
        } else {
          navigate("/onboarding");
        }
      } catch (error) {
        console.log(error);
      }
    },
    [navigate, updateFetching]
  );

  useEffect(() => {
    return onAuthStateChanged(auth, (data) => {
      if (data) {
        updateUser(data);
        getUserData(data);
      } else {
        setTimeout(() => {
          updateFetching(false);
        }, 0);
      }
    });
  }, []);

  return null;
};

export default Auth;
