import { arrayUnion, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { BLOCK_USER_COLLECTION_NAME, USER_COLLECTION_NAME } from "../constants/databaseTypes";
import { db } from "../utils/firebase";
import { IUserProfileData } from "../constants/types";

export const getUserDoc = async (userId: string) => {
  const docRef = doc(db, USER_COLLECTION_NAME, userId);
  const docResult = await getDoc(docRef);
  const docData = docResult.data() as Omit<IUserProfileData, "id">;

  if (!docData) return null;

  return { id: docResult.id, ...docData };
};

export const blockUser = async (tutorId: string, userId: string) => {
  const docRef = doc(db, BLOCK_USER_COLLECTION_NAME, tutorId);

  await setDoc(docRef, { tutorId, blockedUsers: arrayUnion(userId) }, { merge: true });

  return true;
};
