import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { step3State } from ".";
import { FormInputWrapper } from "./Signup.styled";
import { Button } from "../../../components";
import TextField from "../../../components/TextField";
import Notes from "../../../components/Notes";

interface IStep3Props {
  handleSubmit: (values: typeof step3State) => void;
}

const step3ValidationSchema = Yup.object({
  aadharNo: Yup.string().required("This field is required"),
  bankAccountNo: Yup.string().required("This field is required"),
  ifscCode: Yup.string().required("This field is required"),
});

const Step3: React.FC<IStep3Props> = ({ handleSubmit }) => {
  return (
    <Formik
      initialValues={step3State}
      validationSchema={step3ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <h2 style={{ marginBottom: "10px" }}>Next Up, Bank Details</h2>
          <p style={{ marginBottom: "30px" }}>sed ut perspiciatis unde omins omnis omnis omins.</p>
          <FormInputWrapper>
            <TextField
              name="aadharNo"
              placeholder="Aadhar Card Number"
              label="Aadhar Card Number"
            />
            <TextField
              name="bankAccountNo"
              placeholder="Bank Account Number"
              label="Bank Account Number"
            />
            <TextField name="ifscCode" placeholder="IFSC Code" label="IFSC Code" />
          </FormInputWrapper>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Creating your profile..." : "Next"}
          </Button>
          <div style={{ marginTop: "50px" }}>
            <Notes
              title="Note"
              description=" We need some time to verify your details. You will be notified via your e-mail and on
              the Talkgram Dahboard after the verification"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step3;
