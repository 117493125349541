import {
  DocumentData,
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import { BOOKINGS_COLLECTION_NAME, IBookingSession, ITutorProfileData } from "../constants/types";
import { TUTOR_COLLECTION_NAME, TUTOR_ACCOUNTS_COLLECTION_NAME } from "../constants/databaseTypes";
import { startOfMonth } from "date-fns";

export const getTutorDoc = async (id: string) => {
  const docRef = doc(db, TUTOR_COLLECTION_NAME, id);
  const tutorData = (await getDoc(docRef)).data() as Omit<ITutorProfileData, "id">;

  return { id: id, ...tutorData };
};

export const getTutorCancelledSession = async (id: string) => {
  const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
  const q = query(
    colRef,
    where("tutor", "==", id),
    where("status", "==", "TUTOR_CANCELLED"),
    where("startTime", ">", startOfMonth(new Date()))
  );

  const data = await getDocs(q);

  return data.size;
};

export const getTutorAccountDetails = async (id: string) => {
  const docRef = doc(db, TUTOR_ACCOUNTS_COLLECTION_NAME, id);

  const data = await getDoc(docRef);

  return { ...data.data(), id: data.id };
};

export const updateTutorAccountDetails = async (id: string, data: DocumentData) => {
  const docRef = doc(db, TUTOR_ACCOUNTS_COLLECTION_NAME, id);

  await updateDoc(docRef, { ...data, updatedAt: Timestamp.now(), verified: false });
};

export const calculatePenalties = (sessions: IBookingSession[]) => {
  let penalties = 0;

  sessions.forEach((session) => {
    if (!session.timelogs) return;

    const tutorLogs = session.timelogs.filter((log) => log.participantId === session.tutor);

    if (tutorLogs.length > 0) {
      const earliestTutorStartTime = tutorLogs.reduce((earliest, log) => {
        return log.startTime < earliest ? log.startTime : earliest;
      }, tutorLogs[0].startTime);

      const sessionStartTime = new Date(session.startTime.toDate());
      const tutorStartTime = new Date(earliestTutorStartTime);

      const delayInMinutes = (tutorStartTime.getTime() - sessionStartTime.getTime()) / (1000 * 60);

      if (delayInMinutes > 0) {
        penalties += Math.ceil(delayInMinutes) * 10; // 10 per minute of delay
      }

      // console.log(`Tutor Penalty for Session ${session.id}: ${penalty}`);
    }
  });

  return penalties;
};
