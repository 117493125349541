import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 0 0 15px;
`;

interface ISectionHeader {
  title: string;
  description?: string;
}

const SectionHeader: React.FC<ISectionHeader> = ({ title, description }) => {
  return (
    <Container>
      <div>
        <h5 className="section-title mb-8">{title}</h5>
        {description && <p className="s-14">{description}</p>}
      </div>
    </Container>
  );
};

export default SectionHeader;
