import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import ReactCountdown from "react-countdown";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";

import Button from "./Button";

import { ReactComponent as UiTimeIcon } from "../assets/icons/uiw_time.svg";
import FeedbackModal from "./Modal/FeedbackModal";
import { IBookingSession, ITutorProfileData, IUserProfileData } from "../constants/types";
import { customFormat, getLocaleDate, formatFromToDate } from "../constants/formatDate";
import { blockUser, getUserDoc } from "../services/userService";
import { getTutorDoc } from "../services/tutorService";
import { userStore } from "../store/userStore";
import { deleteBookSessionDoc } from "../services/bookSessionService";
import { config } from "../constants/config";
import MenuDropdown from "./MenuDropdown";
import toast from "react-hot-toast";
import UserDetailsModal from "./Modal/UserDetailsModal";

type ISessionType = "upcoming" | "previous" | "missed";

interface ISessionCardProps extends IBookingSession {
  type: ISessionType;
}

const SessionCard: React.FC<ISessionCardProps> = (props) => {
  const {
    type,
    description,
    topic,
    startTime,
    endTime,
    user,
    tutor,
    meetingId,
    id,
    status,
    currentSession,
    topicInfo,
    isLastSession,
    demoClass,
  } = props;
  const [showFeedback, setShowFeedback] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const profileData = userStore((store) => store.profileData);
  const userCancelledSession = userStore((store) => store.userCancelledSession);
  const tutorInfo = userStore((store) => store.user);
  const [tutorData, setTutorData] = useState<ITutorProfileData | null>(null);
  const [userData, setUserData] = useState<IUserProfileData | null>(null);
  const navigate = useNavigate();

  const queryParams = new URLSearchParams({
    meetingId: tutor + user,
    webcamEnabled: "true",
    micEnabled: "true",
  });

  const sessionLink = `https://meet.talkgram.in/${meetingId}/?username=${
    profileData?.username
  }&uid=${tutor}&redirect_url=${encodeURIComponent(window.origin)}`;

  const redirectToMeet = () => {
    window.open(sessionLink, "_blank");
  };
  const handleGetUserData = useCallback(async () => {
    try {
      const userData = await getUserDoc(user);
      const tutorData = await getTutorDoc(tutor);
      setUserData(userData);
      setTutorData(tutorData);
    } catch (error) {
      console.log(error);
    }
  }, [tutor, user]);

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  useEffect(() => {
    const overallEndTime = dayjs(startTime.toDate()).add(30, "minutes");

    if (dayjs().isAfter(overallEndTime)) return;

    const intervalId = setInterval(() => {
      const start = dayjs(startTime.toDate()).add(config.SHOW_FEEDBACK_MINUTE, "minutes");
      const end = dayjs(startTime.toDate()).add(config.HIDE_FEEDBACK_MINUTE, "minutes");

      if (dayjs().isAfter(start) && dayjs().isBefore(end)) {
        setShowFeedback(true);
      } else {
        setShowFeedback(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  const handleCancelSession = async () => {
    try {
      const isConfirm = confirm("Are you sure. you want to cancel session ");
      if (!isConfirm) return;

      await deleteBookSessionDoc(id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!tutorInfo) return alert("inavlid user");

      await blockUser(tutorInfo.uid, user);

      toast.success("User blocked successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const renderUserCancelledTab = (
    <StyledSessionControls>
      <Button variant="error" style={{ pointerEvents: "none" }}>
        Cancelled by User
      </Button>
    </StyledSessionControls>
  );

  const renderTutorCancelledTab = (
    <StyledSessionControls>
      <Button variant="error" style={{ pointerEvents: "none" }}>
        You cancelled session
      </Button>
    </StyledSessionControls>
  );

  const renderMissedSession =
    status === "TUTOR_MISSED" ? (
      <Button variant="error" style={{ pointerEvents: "none" }}>
        Missed Class
      </Button>
    ) : status === "MISSED" ? (
      <Button variant="error" style={{ pointerEvents: "none" }}>
        User Missed Class
      </Button>
    ) : (
      <Button variant="error" style={{ pointerEvents: "none" }}>
        User Missed Class (Pre-Informed)
      </Button>
    );

  return (
    <StyledSessionCard type={type}>
      {!Boolean(demoClass) && type === "upcoming" && isLastSession && (
        <StyledTag>
          <p>
            <InfoIcon color="error" />
            Last Session for {userData?.displayName}
          </p>
        </StyledTag>
      )}
      {Boolean(demoClass) && (
        <StyledTag variant="info">
          <p>
            <InfoIcon color="info" />
            Demo class
          </p>
        </StyledTag>
      )}
      <StyledMenuVert>
        <MenuDropdown>
          <MenuItem onClick={handleBlockUser}>Block User</MenuItem>
        </MenuDropdown>
      </StyledMenuVert>
      <StyledSessionHeader>
        <BlockLeft type={type}>
          <div className="align-center" style={{ paddingLeft: 0 }}>
            <p className="s-14" style={{ textTransform: "uppercase" }}>
              {customFormat(startTime.toDate(), "ddd")}
            </p>
            <h4>{getLocaleDate(startTime.toDate())}</h4>
          </div>
          <div className="align-start">
            <p className="s-14 flex-center">
              <UiTimeIcon />
              <span className="ml-8">{formatFromToDate(startTime.toDate(), endTime.toDate())}</span>
            </p>
            <div className="profiles">
              {userData && userData.profileImg ? (
                <img
                  src={userData.profileImg}
                  alt=""
                  title={userData?.displayName}
                  onClick={() => setOpenUserModal(true)}
                />
              ) : (
                <span className="avatar-fallback-text" title={userData?.displayName}>
                  {userData?.displayName.slice(0, 1)}
                </span>
              )}
              {tutorData && <img src={tutorData.profileImg} alt="" title={tutorData.username} />}
            </div>
          </div>
          <div className="align-start content">
            <p className="s-14">Session with</p>
            <h4 className="s-16 text-success">{userData?.displayName}</h4>
          </div>
        </BlockLeft>
        <SectionTwo>
          <h4 className="s-16">
            {topicInfo
              ? `${topicInfo.category} - ${topicInfo.title}`
              : typeof topic === "string"
              ? topic
              : "Random Topic"}
          </h4>
          {demoClass && (
            <a
              href="https://docs.google.com/document/d/1c-jehzV5dWPu-YX6liFNJjemeXM700jWpJ7Hlu42hvY/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="color-primary"
              style={{ textDecoration: "underline" }}
            >
              Click here to see "Demo Class Guidelines"
            </a>
          )}
          {description && (
            <p className="s-14">
              User Description - <b>{description}</b>
            </p>
          )}
        </SectionTwo>
        <BlockRight>
          {type === "previous" ? (
            status === "USER_CANCELLED" ? (
              renderUserCancelledTab
            ) : status === "TUTOR_CANCELLED" ? (
              renderTutorCancelledTab
            ) : (
              <StyledSessionControls>
                {props["feedbackFromTutor"] ? (
                  <Button variant="secondary" onClick={() => setOpenFeedbackModal(true)}>
                    View Feedback
                  </Button>
                ) : (
                  <Button onClick={() => setOpenFeedbackModal(true)}>Provide Feedback</Button>
                )}
                {topicInfo && (
                  <Button
                    style={{
                      backgroundColor: "transparent",
                    }}
                    variant="secondary"
                    onClick={() => {
                      navigate(
                        `/lesson-plan/?category=${topicInfo?.category}&title=${topicInfo?.title}`
                      );
                    }}
                  >
                    Lesson Plan
                  </Button>
                )}
              </StyledSessionControls>
            )
          ) : null}
          {type === "missed" && renderMissedSession}
        </BlockRight>
      </StyledSessionHeader>
      {type === "upcoming" && (
        <>
          {status === "USER_CANCELLED" ? (
            renderUserCancelledTab
          ) : status === "TUTOR_CANCELLED" ? (
            renderTutorCancelledTab
          ) : (
            <StyledSessionControls>
              <ReactCountdown
                date={startTime.toDate()}
                renderer={({ completed }) => {
                  if (completed) {
                    return (
                      <Button
                        onClick={() => {
                          redirectToMeet();
                        }}
                      >
                        Join Session
                      </Button>
                    );
                  } else {
                    return <Button disabled>Join Session</Button>;
                  }
                }}
              />
              <Button
                style={{
                  backgroundColor: "transparent",
                }}
                variant="secondary"
                onClick={() => {
                  navigate(
                    `/lesson-plan/?category=${topicInfo?.category}&title=${topicInfo?.title}`
                  );
                }}
              >
                Lesson Plan
              </Button>
              {/* <Button variant="secondary">Edit Session</Button> */}
              {userCancelledSession !== undefined &&
                userCancelledSession < config.MAXIMUM_TUTOR_CANCEL_SESSION &&
                dayjs(startTime.toDate()).diff(dayjs(), "hour") >=
                  config.CANCEL_SESSION_DURATION && (
                  <Button variant="error" onClick={() => handleCancelSession()}>
                    Cancel Session
                  </Button>
                )}
              {showFeedback && (
                <>
                  {props["feedbackFromTutor"] ? (
                    <Button variant="secondary" onClick={() => setOpenFeedbackModal(true)}>
                      View Feedback
                    </Button>
                  ) : (
                    <Button onClick={() => setOpenFeedbackModal(true)}>Provide Feedback</Button>
                  )}
                </>
              )}
            </StyledSessionControls>
          )}
        </>
      )}
      {openFeedbackModal && (
        <FeedbackModal
          isOpen={openFeedbackModal}
          handleClose={() => setOpenFeedbackModal(false)}
          {...props}
          feedbackFromTutor={props.feedbackFromTutor}
        />
      )}
      {userData && openUserModal && (
        <UserDetailsModal handleClose={() => setOpenUserModal(false)} userProfileData={userData} />
      )}
    </StyledSessionCard>
  );
};

const StyledSessionCard = styled.div<{ type: ISessionType }>`
  border-radius: 8px;
  border: 0.7px solid #ede7df;
  background: #fceede;
  padding: 20px;
  position: relative;

  ${(props) =>
    (props.type === "previous" || props.type === "missed") &&
    `
      border: 0.7px solid #ede7df;
      background: #fff;
  `}
`;

const StyledSessionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const BlockLeft = styled.div<{ type: ISessionType }>`
  display: flex;

  @media (max-width: 968px) {
    flex-wrap: wrap;
  }

  ${(props) =>
    props.type === "missed" &&
    `
      opacity:0.5;
  `}

  > div {
    padding: 0 20px;
    border-right: 1px solid #ede7df;

    @media (max-width: 768px) {
      // padding-bottom: 15px;
    }

    &:nth-child(1) {
      align-items: flex-start !important;

      @media (max-width: 768px) {
        width: 40%;
        border-bottom: 1px solid #ede7df;
      }
    }
    &:nth-child(2) {
      @media (max-width: 768px) {
        width: 60%;
        border-right: none;
        border-bottom: 1px solid #ede7df;
      }
    }
    &:nth-child(3) {
      @media (max-width: 768px) {
        width: 100%;
        border-right: none;
        padding-top: 15px;
        padding-left: 0;
      }
    }

    &:nth-child(4) {
      @media (max-width: 768px) {
        width: 100%;
        border-right: none;
        padding-left: 0;
      }
    }

    &:last-child {
      border-right: none;
    }

    &.align-center {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    &.align-start {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    &.content {
      // @media (max-width: 968px) {
      //   padding-top: 10px;
      //   margin-top: 10px;
      //   width: 100%;
      // }
    }

    h4 {
      line-height: 120%;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .profiles {
      display: flex;
      align-items: flex-start;
      --size: 24px;

      img {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;

        &:nth-child(2) {
          margin-left: -5px;
        }
      }

      .avatar-fallback-text {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background-color: var(--primary);
        color: #fff;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
  }
`;

const SectionTwo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BlockRight = styled.div`
  display: flex;
`;

const StyledSessionControls = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const StyledTag = styled.div<{ variant?: "info" | "error" | "success" }>`
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid rgb(255, 140, 140);
  background: rgba(255, 52, 52, 0.14);

  ${(props) =>
    props?.variant === "info" &&
    `border: 1px solid #0288d1;
    background: rgb(2 136 209 / 20%);
    
  `}

  p {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const StyledMenuVert = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export default SessionCard;
