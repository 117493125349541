import React, { useState } from "react";
import { ITutorAccountDetails } from "../../../constants/types";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import styled from "styled-components";
import toast from "react-hot-toast";

import { Button } from "../../../components";
import { updateTutorAccountDetails } from "../../../services/tutorService";
import IosSwitch from "../../../components/IosSwitch";

interface IAccountInfo extends ITutorAccountDetails {
  tutorId: string;
  setData: React.Dispatch<React.SetStateAction<ITutorAccountDetails | null>>;
}

const AccountInfo: React.FC<IAccountInfo> = ({
  aadharNo,
  bankAccountNo,
  ifscCode,
  tutorId,
  setData,
  verified,
}) => {
  const [edit, setEdit] = useState(false);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    try {
      await updateTutorAccountDetails(tutorId, values);

      setData((d) => ({ ...d, ...values }));
      actions.setSubmitting(false);
      toast.success(
        "Account details updated. Admin will verify your account details and approve it in a while",
        { duration: 4000 }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <Button
          type="button"
          variant="secondary"
          style={{ pointerEvents: "auto" }}
          onClick={() => setEdit((e) => !e)}
        >
          {edit ? "Cancel" : "Edit"}
        </Button>
      </StyledHeader>
      <Formik
        initialValues={{ aadharNo, bankAccountNo, ifscCode }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form style={{ pointerEvents: !edit ? "none" : "initial" }}>
            <StyledFlex>
              <h5>Account Verified</h5>
              <IosSwitch checked={Boolean(verified)} label={Boolean(verified) ? "YES" : "NO"} />
            </StyledFlex>
            <FormInput>
              <label htmlFor="aadharNo">Aadhar No</label>
              <FormInputField>
                <Field name="aadharNo" type="aadharNo" placeholder="Enter your aadhar no." />
              </FormInputField>
              <ErrorMessage name="aadharNo" component="div" className="error-text" />
            </FormInput>
            <FormInput>
              <label htmlFor="bankAccountNo">Bank Account No </label>
              <FormInputField>
                <Field
                  name="bankAccountNo"
                  type="bankAccountNo"
                  placeholder="Enter your back account no"
                />
              </FormInputField>
              <ErrorMessage name="bankAccountNo" component="div" className="error-text" />
            </FormInput>
            <FormInput>
              <label htmlFor="ifscCode">Ifsc Code</label>
              <FormInputField>
                <Field name="ifscCode" type="ifscCode" placeholder="Enter your ifsc code" />
              </FormInputField>
              <ErrorMessage name="ifscCode" component="div" className="error-text" />
            </FormInput>
            {edit && (
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Saving Changes..." : "Save Changes"}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding: 20px 0 50px;
  max-width: 560px;
  width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-secondary);
  }

  input {
    margin: 10px 0 5px;
  }

  .error-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-error);
  }
`;

const FormInputField = styled.div`
  position: relative;

  input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2e2d2d;
    padding: 14px 20px;
    background: #fff4fb;
    border: 1px solid rgba(25, 91, 90, 0.3);
    border-radius: 8px;

    &:focus {
      border: 1px solid transparent;
      outline: 1px solid var(--primary);
    }
  }

  .icon {
    position: absolute;
    top: calc(50% + 2.5px);
    right: 16px;
    width: 24px;
    height: 24px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`;

export default AccountInfo;
