import { doc, getDoc } from "firebase/firestore";
import { GENERAL_COLLECTION_NAME } from "../constants/databaseTypes";
import { db } from "../utils/firebase";
import { ITutorFee } from "../constants/types";

export const getPriceDoc = async () => {
  const docRef = doc(db, GENERAL_COLLECTION_NAME, "price");
  const data = (await getDoc(docRef)).data() as Omit<ITutorFee, "id">;

  return data;
};
