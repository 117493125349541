import { ErrorMessage, Field } from "formik";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface ITextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

const TextField: React.FC<ITextFieldProps> = ({ name, label, ...rest }) => {
  return (
    <FormInput>
      <label htmlFor={name}>{label}</label>
      <FormInputField>
        <Field id={name} name={name} {...rest} />
      </FormInputField>
      <ErrorMessage name={name} component="div" className="error-text" />
    </FormInput>
  );
};

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-secondary);
  }

  input {
    margin: 10px 0 5px;
  }

  .error-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-error);
  }
`;

export const FormInputField = styled.div`
  input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2e2d2d;
    padding: 14px 20px;
    background: #fff4fb;
    border: 1px solid rgba(25, 91, 90, 0.3);
    border-radius: 8px;

    &:focus {
      border: 1px solid transparent;
      outline: 1px solid var(--primary);
    }
  }
`;

export default TextField;
