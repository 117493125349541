import testimonial1 from "../assets/images/testimonial1.png";
import testimonial2 from "../assets/images/testimonial2.png";
import testimonial3 from "../assets/images/testimonial1.png";
import testimonial4 from "../assets/images/testimonial1.png";

export const specialization = [
  "Grammar",
  "Business English",
  "Pronunciation",
  "Interview Prep",
  "Accent",
];

export const interests = [
  "Career & Goals",
  "Food & Drinks",
  "Cooking",
  "Exercise & Health",
  "Books & Literature",
  "Sports",
  "Travel",
  "Life & Spirituality",
  "Gaming",
];

export const testimonials = [
  {
    author: "Ananthan",
    description:
      "I've tried numerous language apps, but the English fluency improvement app with online classes and tutors truly stands out! The interactive classes helped me build my speaking confidence, and the tutors were patient and skilled. My fluency has improved remarkably, and I'm now able to communicate more effectively",
  },
  {
    author: "Kiran",
    description:
      "As a non-native English speaker, I was hesitant to join online classes. However, this app's English fluency program changed my perspective completely. The personalized attention from the tutors in the online classes made a huge difference. I'm now able to express myself in English more naturally and fluently.",
  },
  {
    author: "Sowmya Mishra",
    description:
      " I struggled with English fluency for years, but this app made the journey enjoyable. The tutors were knowledgeable and supportive, and my language skills have improved beyond my expectations.",
  },
  {
    author: "Prabakar",
    description:
      "Talkgram helped me refine my grammar, expand my vocabulary, and speak confidently in various real-life situations. If you're serious about enhancing your English, this app is a must-try.",
  },
];

export const authSliderImages = [testimonial1, testimonial2, testimonial3, testimonial4];
