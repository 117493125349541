import React, { useCallback, useEffect, useState } from "react";
import { customFormat, formatFromToDate } from "../../constants/formatDate";
import {
  IBookingSession,
  IUserProfileData,
  TBookingStatus,
  USER_COLLECTION_NAME,
} from "../../constants/types";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { calculateTimeDifference } from "../../constants/formatter";

const AttendanceCard: React.FC<IBookingSession> = ({
  startTime,
  endTime,
  user,
  timelogs,
  tutor,
  meetingId,
  status,
}) => {
  const [userData, setUserData] = useState<IUserProfileData | null>(null);

  const handleGetUserData = useCallback(async () => {
    try {
      const docRef = doc(db, USER_COLLECTION_NAME, user);
      const userData = await getDoc(docRef);
      setUserData(userData.data() as IUserProfileData);
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  const tutorTimelog = timelogs ? timelogs?.filter((f) => f.participantId === tutor) : [];

  return (
    <tr className="">
      <td>{userData ? userData.displayName : ""}</td>
      <td>{meetingId}</td>
      <td>{customFormat(startTime.toDate(), "DD/MM/YYYY")}</td>
      <td>{formatFromToDate(startTime.toDate(), endTime.toDate())}</td>
      <td>{status === TBookingStatus.TUTOR_MISSED ? "Absent" : "Present"}</td>
      <td>
        {tutorTimelog.length
          ? tutorTimelog.map((m) => customFormat(startTime.toDate(), "hh:mm a"))
          : "-"}
      </td>
      <td>
        {tutorTimelog.length
          ? tutorTimelog.map((m) => calculateTimeDifference(m.overallDuration))
          : "-"}
      </td>
      <td></td>
    </tr>
  );
};

export default AttendanceCard;
