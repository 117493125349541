import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { ErrorMessage, Field, Form, Formik } from "formik";
import toast from "react-hot-toast";

import Backdrop from "./Backdrop";
import Button from "../Button";
import ReactModal from "./ReactModal";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { updateBookSessionDoc } from "../../services/bookSessionService";
import { IBookingSession, INewTutorFeedback } from "../../constants/types";
import {
  fluencyFeedbackItems,
  grammerFeedback,
  grammerTitles,
  pronunciationFeedbackItems,
  skillsLable,
  vocabulary,
  vocabularyFeedback,
} from "../../constants/data";
import {
  feedbackValidationSchema,
  feedbackValidationSchemaDemoClass,
} from "../../constants/validationSchema";
import Tooltip from "../Tooltip";
import { customFormat, formatFromToDate } from "../../constants/formatDate";

const modalVaraints = {
  initial: {
    opacity: 0,
    scale: 0.5,
    x: "-50%",
    y: "-50%",
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.3 },
    scale: 1,
    x: "-50%",
    y: "-50%",
  },
  exit: {
    opacity: 0,
    scale: 0,
    x: "-50%",
    y: "-50%",
  },
};

interface IFeedbackModal extends IBookingSession {
  isOpen: boolean;
  handleClose?: () => void;
  id: string;
  feedbackFromTutor?: INewTutorFeedback;
}

const initialValues = {
  status: "COMPLETED",
  fluency: {
    rating: 0,
    feedback: "",
  },
  pronunciation: {
    rating: 0,
    feedback: "",
  },
  vocabulary: {
    general: "",
    range: "",
    wordChoicePrecision: "",
  },
  grammar: {
    tenses: "",
    mostErrors: "",
    articlesAndPrepositions: "",
    subjectVerb: "",
    pronounUsage: "",
    other: "",
  },
  skills: {
    confidence: 0,
    passion: 0,
    listeningComprehension: 0,
    conversationBuilding: 0,
  },
  suggestions: {
    month: 0,
    sessionsPerWeek: 0,
  },
  generalFeedback: "",
  homework: "",
};

const FeedbackModal: React.FC<IFeedbackModal> = ({
  isOpen,
  handleClose,
  id,
  feedbackFromTutor,
  demoClass,
  startTime,
  endTime,
}) => {
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await updateBookSessionDoc(id, {
        status: values.status,
        feedbackFromTutor: {
          ...values,
        },
      });
      if (handleClose) handleClose();
      console.log("---USER FEEDBACK UPDATED---");
      toast.success("Feedback Updated Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <ReactModal>
      <Backdrop handleClose={handleClose} isOpen={isOpen}>
        <AnimatePresence>
          {isOpen && (
            <StyledFeedbackModal
              onClick={(e) => e.stopPropagation()}
              variants={modalVaraints}
              animate="animate"
              initial="initial"
              exit="exit"
            >
              <ProfileContainer>
                <div className="flex-between">
                  <div className="flex">
                    <div className="flex-column">
                      <h4>Feedback</h4>
                    </div>
                  </div>
                  <div role="button" className="pointer" onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </div>
                <div className="flex-center">
                  <div className="flex-center">
                    <ClockIcon />
                    <span className="s-14 ml-10">
                      {formatFromToDate(startTime.toDate(), endTime.toDate())}
                    </span>
                  </div>
                  <div className="flex-center">
                    <CalendarIcon />
                    <span className="s-14 ml-10">
                      {customFormat(startTime.toDate(), "DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              </ProfileContainer>
              <Formik
                initialValues={feedbackFromTutor || initialValues}
                validationSchema={
                  demoClass ? feedbackValidationSchemaDemoClass : feedbackValidationSchema
                }
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ isSubmitting, values, setFieldValue, errors, isValid }) => (
                  <Form>
                    <StlyedFeedbackForm
                      style={{ pointerEvents: feedbackFromTutor ? "none" : "initial" }}
                    >
                      <div className="flex-column">
                        <p className="title">User Status</p>
                        <StyledTagList>
                          <span
                            className={
                              values.status === "COMPLETED"
                                ? "tag-variant-one success"
                                : "tag-variant-one"
                            }
                            onClick={() => setFieldValue("status", "COMPLETED")}
                          >
                            Joined
                          </span>
                          <span
                            className={
                              values.status === "MISSED"
                                ? "tag-variant-one error"
                                : "tag-variant-one"
                            }
                            onClick={() => setFieldValue("status", "MISSED")}
                          >
                            Missed
                          </span>
                          <span
                            className={
                              values.status === "USER_MISSED_PREINFORMED"
                                ? "tag-variant-one error"
                                : "tag-variant-one"
                            }
                            onClick={() => setFieldValue("status", "USER_MISSED_PREINFORMED")}
                          >
                            User Missed (Pre-informed)
                          </span>
                        </StyledTagList>
                      </div>
                      {values.status === "COMPLETED" && (
                        <>
                          <h4>Interaction Analysis</h4>
                          {Object.keys(initialValues["skills"]).map((k) => (
                            <div className="flex-column" key={k}>
                              <p className="title" style={{ textTransform: "capitalize" }}>
                                {skillsLable[k as keyof typeof skillsLable]}
                              </p>
                              <StyledNumberList>
                                {Array.from({ length: 10 }).map((_, i) => (
                                  <span
                                    className={
                                      values.skills[k as keyof (typeof values)["skills"]] === i + 1
                                        ? "active"
                                        : ""
                                    }
                                    key={i.toString()}
                                    onClick={() => setFieldValue(`skills.${k}`, i + 1)}
                                  >
                                    {i + 1}
                                  </span>
                                ))}
                              </StyledNumberList>
                              <ErrorMessage
                                name={`skills.${k}`}
                                className="error-text"
                                component="div"
                              />
                            </div>
                          ))}
                          <h4>Language Proficiency</h4>

                          {/** -------- FLUENCY FEEDACK --------*/}

                          <div className="flex-column">
                            <div className="flex-between">
                              <p className="title">Fluency</p>
                              <div>
                                <StyledStarList>
                                  {Array.from({ length: 5 }).map((_, i) => (
                                    <span
                                      className={i + 1 <= values.fluency.rating ? "active" : ""}
                                      key={i.toString()}
                                      onClick={() => setFieldValue("fluency.rating", i + 1)}
                                    >
                                      <StarIcon />
                                    </span>
                                  ))}
                                </StyledStarList>
                                <ErrorMessage
                                  name="fluency.rating"
                                  className="error-text"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div>
                              <StyledEmojiList>
                                {fluencyFeedbackItems.map((data, j) => (
                                  <div
                                    className={values.fluency.feedback === data ? "active" : ""}
                                    key={j.toString()}
                                    onClick={() => {
                                      setFieldValue("fluency.feedback", data);
                                    }}
                                  >
                                    <p>{data}</p>
                                  </div>
                                ))}
                              </StyledEmojiList>
                              <ErrorMessage
                                name="fluency.feedback"
                                className="error-text"
                                component="div"
                              />
                            </div>
                          </div>

                          {/** -------- FLUENCY FEEDACK --------*/}

                          {/** -------- PRONUNCIATION FEEDACK --------*/}

                          <div className="flex-column">
                            <div className="flex-between">
                              <p className="title">Pronunciation</p>
                              <div>
                                <StyledStarList>
                                  {Array.from({ length: 5 }).map((_, i) => (
                                    <span
                                      className={
                                        i + 1 <= values.pronunciation.rating ? "active" : ""
                                      }
                                      key={i.toString()}
                                      onClick={() => setFieldValue("pronunciation.rating", i + 1)}
                                    >
                                      <StarIcon />
                                    </span>
                                  ))}
                                </StyledStarList>
                                <ErrorMessage
                                  name="pronunciation.rating"
                                  className="error-text"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div>
                              <StyledEmojiList>
                                {pronunciationFeedbackItems.map((data, j) => (
                                  <div
                                    className={
                                      values.pronunciation.feedback === data.name ? "active" : ""
                                    }
                                    key={j.toString()}
                                    onClick={() => {
                                      setFieldValue("pronunciation.feedback", data.name);
                                    }}
                                  >
                                    <p>{data.name} </p>
                                    {data.info && (
                                      <Tooltip title={data.info}>
                                        <InfoOutlinedIcon className="stats-info-icon" />
                                      </Tooltip>
                                    )}
                                  </div>
                                ))}
                              </StyledEmojiList>
                              <ErrorMessage
                                name="pronunciation.feedback"
                                className="error-text"
                                component="div"
                              />
                            </div>
                          </div>

                          {/** -------- PRONUNCIATION FEEDACK --------*/}

                          {/** -------- VOCABULARY FEEDACK --------*/}

                          <div className="flex-column">
                            <p className="title">Vocabulary</p>
                            <StyledEmojiList>
                              {vocabularyFeedback.map((data, j) => (
                                <div
                                  className={values.vocabulary.general === data ? "active" : ""}
                                  key={j.toString()}
                                  onClick={() => {
                                    setFieldValue("vocabulary.general", data);
                                  }}
                                >
                                  <p>{data}</p>
                                </div>
                              ))}
                            </StyledEmojiList>
                            <ErrorMessage
                              name="vocabulary.general"
                              className="error-text"
                              component="div"
                            />
                          </div>

                          <div className="flex-column">
                            <p className="title">
                              <span>{vocabulary.vocabularyRange.name}</span>{" "}
                              <Tooltip title={vocabulary.vocabularyRange.info}>
                                <InfoOutlinedIcon className="stats-info-icon" />
                              </Tooltip>
                            </p>
                            <StyledEmojiList>
                              {vocabulary.vocabularyRange.options.map((data, j) => (
                                <div
                                  className={values.vocabulary.range === data ? "active" : ""}
                                  key={j.toString()}
                                  onClick={() => {
                                    setFieldValue("vocabulary.range", data);
                                  }}
                                >
                                  <p>{data}</p>
                                </div>
                              ))}
                            </StyledEmojiList>
                            <ErrorMessage
                              name="vocabulary.range"
                              className="error-text"
                              component="div"
                            />
                          </div>

                          <div className="flex-column">
                            <p className="title">
                              <span>{vocabulary.wordChoicePrecision.name}</span>{" "}
                              <Tooltip title={vocabulary.wordChoicePrecision.info}>
                                <InfoOutlinedIcon className="stats-info-icon" />
                              </Tooltip>
                            </p>
                            <StyledEmojiList>
                              {vocabulary.wordChoicePrecision.options.map((data, j) => (
                                <div
                                  className={
                                    values.vocabulary.wordChoicePrecision === data ? "active" : ""
                                  }
                                  key={j.toString()}
                                  onClick={() => {
                                    setFieldValue("vocabulary.wordChoicePrecision", data);
                                  }}
                                >
                                  <p>{data}</p>
                                </div>
                              ))}
                            </StyledEmojiList>
                            <ErrorMessage
                              name="vocabulary.wordChoicePrecision"
                              className="error-text"
                              component="div"
                            />
                          </div>

                          {/** -------- VOCABULARY FEEDACK --------*/}

                          {/** -------- GRAMMER FEEDACK --------*/}

                          <h5>Grammar</h5>
                          <div className="flex-column" style={{ gap: "20px" }}>
                            <div className="flex-column" style={{ order: 1 }}>
                              <p className="title">Most Errors (Tenses)</p>
                              <StyledEmojiList>
                                {["past", "present", "future"].map((data, j) => (
                                  <div
                                    className={values.grammar.mostErrors === data ? "active" : ""}
                                    key={j.toString()}
                                    onClick={() => {
                                      setFieldValue("grammar.mostErrors", data);
                                    }}
                                  >
                                    <p>{data}</p>
                                  </div>
                                ))}
                              </StyledEmojiList>
                              <ErrorMessage
                                name="grammar.mostErrors"
                                className="error-text"
                                component="div"
                              />
                            </div>
                            {grammerTitles.map((title, index) => (
                              <div
                                key={index.toString()}
                                className="flex-column"
                                style={{ order: index === 0 ? 0 : index + 1 }}
                              >
                                <p className="title">{title.name}</p>
                                <StyledEmojiList>
                                  {grammerFeedback.map((data, j) => (
                                    <div
                                      className={
                                        values.grammar[
                                          title.value as keyof (typeof values)["grammar"]
                                        ] === data
                                          ? "active"
                                          : ""
                                      }
                                      key={j.toString()}
                                      onClick={() => {
                                        setFieldValue(`grammar.${title.value}`, data);
                                      }}
                                    >
                                      <p>{data}</p>
                                    </div>
                                  ))}
                                </StyledEmojiList>
                                <ErrorMessage
                                  name={`grammar.${title.value}`}
                                  className="error-text"
                                  component="div"
                                />
                              </div>
                            ))}
                          </div>
                          <div className="flex-column">
                            <label htmlFor="feedback title">Grammar feedback</label>
                            <textarea
                              readOnly={Boolean(feedbackFromTutor)}
                              value={values.grammar.other}
                              onChange={(e) => setFieldValue("grammar.other", e.target.value)}
                              name="grammar.other"
                              id="grammar.other"
                              placeholder="Give us some feedback to improve ourself"
                            />
                          </div>

                          {/** -------- GRAMMER FEEDBACK --------*/}

                          <div className="flex-column">
                            <h5>General Feedback</h5>
                            <textarea
                              readOnly={Boolean(feedbackFromTutor)}
                              name="generalFeedback"
                              id="generalFeedback"
                              value={values.generalFeedback}
                              onChange={(e) => setFieldValue("generalFeedback", e.target.value)}
                              placeholder="give us some feedback to improve ourself"
                            />
                          </div>

                          {/** HOMEWORK */}

                          <div className="flex-column">
                            <h5>Homework</h5>
                            <textarea
                              readOnly={Boolean(feedbackFromTutor)}
                              name="homework"
                              id="homework"
                              value={values.homework}
                              onChange={(e) => setFieldValue("homework", e.target.value)}
                              placeholder="Provide the user with some assignments to enhance their skills"
                            />
                          </div>

                          {demoClass && (
                            <>
                              <h4>Suggestions</h4>
                              <div className="flex-column">
                                <label htmlFor="month">Month</label>
                                <StyledInput
                                  type="number"
                                  name="suggestions.month"
                                  id="month"
                                  placeholder="suggested month"
                                />
                                <ErrorMessage
                                  name={`suggestions.month`}
                                  className="error-text"
                                  component="div"
                                />
                              </div>
                              <div className="flex-column">
                                <label htmlFor="sessionsPerWeek">Sessions Per Week</label>
                                <StyledInput
                                  type="number"
                                  name="suggestions.sessionsPerWeek"
                                  id="sessionsPerWeek"
                                  placeholder="Sessions Per Week"
                                />
                                <ErrorMessage
                                  name={`suggestions.sessionsPerWeek`}
                                  className="error-text"
                                  component="div"
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {!feedbackFromTutor && !isValid && (
                        <p className="error-text">*Check whether all the fields are filled.</p>
                      )}
                      <div className="flex-between place-center">
                        {!feedbackFromTutor && (
                          <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting your feedback" : "Submit"}
                          </Button>
                        )}
                      </div>
                    </StlyedFeedbackForm>
                  </Form>
                )}
              </Formik>
            </StyledFeedbackModal>
          )}
        </AnimatePresence>
      </Backdrop>
    </ReactModal>
  );
};

const StyledFeedbackModal = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--card-background);
  max-width: 550px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ede7df;
  max-height: 75vh;
  overflow-y: auto;
  transition: max-height 200ms linear;

  @media (max-width: 576px) {
    max-width: 90%;
  }
`;

const ProfileContainer = styled.div`
  padding: 20px;
  background: #fceede;
  position: sticky;
  top: 0;

  .flex-between {
    align-items: flex-start;
    margin-bottom: 15px;

    > .flex {
      display: inline-flex;
      align-items: flex-start;

      .profile_img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }

  > .flex-center {
    gap: 30px;
  }
`;

const StlyedFeedbackForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding: 30px;

  .flex-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .flex-between {
    width: 100%;
  }

  h5 {
    font-size: 20px;
    font-weight: bold;
  }

  textarea {
    border-radius: 8px;
    border: 1px solid #ede7df;
    color: #2e2d2d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    padding: 10px 20px;
    outline-color: rgba(247, 148, 31, 0.2);
    resize: none;
  }
`;

const StyledInput = styled(Field)`
  border-radius: 8px;
  border: 1px solid #ede7df;
  color: #2e2d2d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  padding: 10px 20px;
  outline-color: rgba(247, 148, 31, 0.2);
`;

const StyledEmojiList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid var(--gray-2, #62635e);
    padding: 5px 14px;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 14px;
    background-color: transparent;
    transition: background-color 100ms linear;

    &.active {
      background-color: #fceede;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledStarList = styled.div`
  display: flex;

  > span {
    cursor: pointer;

    &.active {
      svg path {
        fill: var(--primary);
      }
    }
  }
`;

const StyledTagList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;

  .tag-variant-one {
    border-radius: 8px;
    border: 1px solid rgba(204, 204, 204, 0.8);
    background: var(--white, #fff);
    box-shadow: 0px 1px 8px 0px rgba(31, 103, 251, 0.05);
    color: var(--black, #2e2d2d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
    transition: all 200ms linear;
    cursor: pointer;
    text-align: center;
    font-weight: 600;

    &.active {
      border: 1px solid var(--primary-1, #f7941f);
      background: var(--primary-2, #fff1e0);
      box-shadow: 0px 1px 8px 0px rgba(31, 103, 251, 0.05);
    }

    &.success {
      border: 1px solid var(--primary-1, #f7941f);
      background: var(--primary-2, #fff1e0);
      box-shadow: 0px 1px 8px 0px rgba(31, 103, 251, 0.05);
    }

    &.error {
      border: 1px solid var(--error, #f7941f);
      background: rgba(204, 44, 61, 0.3);
      box-shadow: 0px 1px 8px 0px rgba(31, 103, 251, 0.05);
    }
  }
`;

const StyledNumberList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > span {
    font-size: 14px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    border: 1px solid var(--primary);
    cursor: pointer;

    &.active {
      background-color: var(--primary);
    }
  }
`;

export default FeedbackModal;
