import React from "react";
import styled from "styled-components";

interface INotesProps {
  title: string;
  description: string;
}

const Notes: React.FC<INotesProps> = ({ title, description }) => {
  return (
    <StyledNote>
      <h5>{title}</h5>
      <p>{description}</p>
    </StyledNote>
  );
};

const StyledNote = styled.div`
  border-radius: 8px;
  border: 1px solid #eae8e5;
  background: var(--primary-2, #fff1e0);
  box-shadow: 0px 1px 8px 0px rgba(31, 103, 251, 0.05);
  padding: 15px;

  h5 {
    color: var(--primary-1, #f7941f);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  p {
    color: var(--gray-3, #787878);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export default Notes;
