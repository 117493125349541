import { create } from "zustand";
import type { User } from "firebase/auth";
import { ITutorProfileData } from "../constants/types";

type State = {
  user: User | null;
  isFetching: boolean;
  profileData: ITutorProfileData | null;
  userCancelledSession?: number;
};

type Action = {
  updateFetching: (fetching: boolean) => void;
  updateUser: (user: User) => void;
  updateProfileData: (data: ITutorProfileData) => void;
  updateCancelledSession: (value: number) => void;
};

export const userStore = create<State & Action>((set) => ({
  user: null,
  isFetching: true,
  profileData: null,
  updateFetching: (fetching) => set(() => ({ isFetching: fetching })),
  updateUser: (user) => set(() => ({ user })),
  updateProfileData: (data) => set(() => ({ profileData: data })),
  updateCancelledSession: (data) => set(() => ({ userCancelledSession: data })),
}));
