import { ErrorMessage, Field } from "formik";
import React, { TextareaHTMLAttributes } from "react";
import styled from "styled-components";

interface IFormTextarea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
}

const Textarea: React.FC<IFormTextarea> = ({ label, name, ...rest }) => {
  return (
    <StyledFormInput>
      <label htmlFor={name}>{label}</label>
      <Field as="textarea" id={name} name={name} {...rest} />
      <ErrorMessage name={name} component="div" className="error-input" />
    </StyledFormInput>
  );
};

const StyledFormInput = styled.div`
  display: flex;
  flex-direction: column;

  label {
    display: block;
    color: var(--black, #2e2d2d);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .error-input {
    color: tomato;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  textarea {
    border-radius: 8px;
    border: 1px solid #ede7df;
    color: #2e2d2d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    padding: 10px 20px;
    outline-color: rgba(247, 148, 31, 0.2);
    resize: none;
  }
`;

export default Textarea;
