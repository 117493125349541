import React from "react";
import styled from "styled-components";

// import SelectInput from "../SelectInput";
// import { sessionFilters } from "../../constants/data";
import rating from "../../assets/icons/rating.png";
import dollar from "../../assets/icons/dollar.png";
import StatsCard from "../Card/StatsCard";
import CheckIcon from "../../assets/icons/check.svg";
import CloseIcon from "../../assets/icons/close.svg";
import WarningIcon from "../../assets/icons/warning.svg";
import rocket from "../../assets/icons/rocket.png";
import Rating from "../Rating";
import useGetTodaysEarnings from "../../hooks/useGetTodaysEarnings";
import { userStore } from "../../store/userStore";

const TutorSessionChart = () => {
  const { data } = useGetTodaysEarnings();
  const profileData = userStore((store) => store.profileData);

  const ratings =
    profileData?.totalRatings && profileData?.totalRatingsCount
      ? profileData?.totalRatings / profileData?.totalRatingsCount
      : 0;

  return (
    <StyledContainer>
      {/* <StyledTutorSessionChartHeader className="flex-between">
        <h2 className="section-title">Yearly Session Report</h2>
        <div className="flex">
          <div className="secondary-btn pointer">
            Jan- Dec 2023 <ArrowIcon />
          </div>
          <div className="secondary-btn">Missed Sessions</div>
          <SelectInput
            label={
              <>
                <span>Apply Filter</span>
                <FilterIcon />
              </>
            }
            lists={sessionFilters}
          />
        </div>
      </StyledTutorSessionChartHeader> */}
      <StyledUserSessionStats>
        <StyledSessionStatsCard>
          <div className="flex-between">
            <div className="flex-column">
              <h6 className="s-16 mb-8">Today’s Earnings</h6>
              {/* <p className="s-14 mb-12">
                  See what is lined up for you today. Everything at once.
                </p> */}
              <div>
                <h3>Rs {data?.todayEarnings}</h3>
              </div>
            </div>
            <div className="block-right">
              <img src={dollar} alt="" />
            </div>
          </div>
        </StyledSessionStatsCard>
        <StyledSessionStatsCard>
          <div className="flex-between">
            <div className="flex-column">
              <h6 className="s-16 mb-8">Your Average Rating</h6>
              {/* <p className="s-14 mb-12">
                  See what is lined up for you today. Everything at once.
                </p> */}
              <div>
                <h3>{Math.round(ratings)}</h3>
                <Rating rating={Math.round(ratings)} />
              </div>
            </div>
            <div className="block-right">
              <img src={rating} alt="" />
            </div>
          </div>
        </StyledSessionStatsCard>
      </StyledUserSessionStats>
      <StyledGrid>
        <StatsCard title="Today’s Total Sessions" count={data?.totalSessions ?? 0} image={rocket} />
        <StatsCard
          title="Today’s Completed
          Sessions"
          count={data?.completedSession ?? 0}
          image={CheckIcon}
        />
        <StatsCard
          title="Today’s Missed Sessions"
          count={data?.missedSession ?? 0}
          image={WarningIcon}
        />
        <StatsCard
          title="Today’s Cancelled Sessions"
          count={data?.cancelledSession ?? 0}
          image={CloseIcon}
        />
      </StyledGrid>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-bottom: 30px;
  border-radius: 8px;
  // border: 1px solid #ede7df;
  // background: var(--white, #fff);
  // padding: 30px;
`;

// const StyledTutorSessionChartHeader = styled.div`
//   gap: 20px;

//   .flex {
//     display: flex;
//     gap: 12px;

//     .secondary-btn {
//       border-radius: 8px;
//       background: #ecf0ef;
//       display: flex;
//       padding: 10px 16px;
//       align-items: center;
//       gap: 10px;
//       white-space: nowrap;

//       svg {
//         width: 16px;
//         height: 16px;
//       }
//     }
//   }
// `;

const StyledUserSessionStats = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
`;

const StyledSessionStatsCard = styled.div`
  border-radius: 8px;
  border: 1px solid #ede7df;
  background: var(--white, #fff);
  padding: 25px;

  .block-right {
    height: 100px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    background: #fde9d2;
    border-radius: 50%;

    @media (max-width: 600px) {
      height: 50px;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;

      @media (max-width: 600px) {
        width: 25px;
        height: 25px;
      }
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;

    p {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default React.memo(TutorSessionChart);
