import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { onAuthStateChanged, User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import {
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderLeft,
  StyledHeaderRight,
  StyledLogo,
  StyledNavLinks,
  StyledMenu,
  StyledMobileMenu,
} from "./Header.styles";
import { userStore } from "../../store/userStore";
import { auth, db } from "../../utils/firebase";
import { ITutorProfileData } from "../../constants/types";
import Avatar from "../Avatar";
import Sidebar from "./Sidebar";
import logo from "../../assets/logo/logo_short.png";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as BookSessionIcon } from "../../assets/icons/book-session.svg";
import { ReactComponent as SessionIcon } from "../../assets/icons/session.svg";
import { TUTOR_COLLECTION_NAME } from "../../constants/databaseTypes";
import { getTutorCancelledSession } from "../../services/tutorService";
import { useGeneralStore } from "../../store/generalStore";
import { getPriceDoc } from "../../services/generalService";

const Header: React.FC = () => {
  const user = userStore((state) => state.user);
  const profileData = userStore((state) => state.profileData);
  const navigate = useNavigate();
  const updateUser = userStore((state) => state.updateUser);
  const updateFetching = userStore((state) => state.updateFetching);
  const updateProfileData = userStore((state) => state.updateProfileData);
  const updateCancelledSession = userStore((state) => state.updateCancelledSession);
  const [openSidebar, setOpenSidebar] = useState(false);
  const updateFee = useGeneralStore((store) => store.updateFee);

  const handleGetPriceData = useCallback(async () => {
    try {
      const data = await getPriceDoc();
      if (data) updateFee(data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    handleGetPriceData();
  }, [handleGetPriceData]);

  const getUserData = useCallback(async (user: User) => {
    try {
      const userDocRef = doc(db, TUTOR_COLLECTION_NAME, user.uid);
      const userSnapshot = await getDoc(userDocRef);

      setTimeout(() => {
        updateFetching(false);
      }, 0);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data() as ITutorProfileData;
        updateProfileData(userData);
        updateCancelledSession(await getTutorCancelledSession(user.uid));
      } else {
        navigate("/onboarding");
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    return onAuthStateChanged(auth, (data) => {
      if (data) {
        updateUser(data);
        getUserData(data);
      } else {
        navigate("/login");
        setTimeout(() => {
          updateFetching(false);
        }, 0);
      }
    });
  }, []);

  return (
    <StyledHeader>
      <StyledHeaderContainer>
        <StyledHeaderLeft>
          <StyledLogo>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </StyledLogo>
          <StyledNavLinks>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/bookings">Bookings</NavLink>
            </li>
            <li>
              <NavLink to="/sessions">Sessions</NavLink>
            </li>
            <li>
              <NavLink to="/earnings">Earnings</NavLink>
            </li>
            <li>
              <NavLink to="/attendance">Attendance</NavLink>
            </li>
          </StyledNavLinks>
        </StyledHeaderLeft>
        <StyledHeaderRight>
          {/* <h5>{user && user.displayName}</h5> */}
          <Link to="/profile">
            {user && (
              <Avatar profileImg={profileData?.profileImg} username={user.displayName as string} />
            )}
          </Link>
          <StyledMenu role="button" onClick={() => setOpenSidebar(true)}>
            <MenuIcon />
          </StyledMenu>
        </StyledHeaderRight>
      </StyledHeaderContainer>
      <Sidebar openSidebar={openSidebar} handleClose={() => setOpenSidebar(false)} />

      <StyledMobileMenu>
        <div>
          <NavLink to="/">
            <HomeIcon />
          </NavLink>
          <NavLink to="/bookings">
            <BookSessionIcon />
          </NavLink>
          <NavLink to="/sessions">
            <SessionIcon />
          </NavLink>
        </div>
      </StyledMobileMenu>
    </StyledHeader>
  );
};

export default Header;
