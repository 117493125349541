import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { userStore } from "./store/userStore";
import Home from "./pages/Home";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import SplashScreen from "./components/SplashScreen";
import QuestionSlider from "./components/Slider";
import Header from "./components/Header";
import Copyright from "./components/Copyright";
import Auth from "./pages/Auth/Auth";
import Sessions from "./pages/Sessions";
import Profile from "./pages/Profile";
import AttendancePage from "./pages/AttendancePage";
import EarningsPage from "./pages/EarningsPage";
import BookingsPage from "./pages/BookingsPage";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import Dispute from "./pages/Dispute";
import LessonPlan from "./pages/lessonPlan";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f7941f",
    },
  },
});

const App: React.FC = () => {
  const isFetching = userStore((state) => state.isFetching);

  return (
    <div className="app">
      {isFetching && <SplashScreen />}
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            element={
              <div>
                <Header />
                <Outlet />
                <Copyright />
              </div>
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/bookings" element={<BookingsPage />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/earnings" element={<EarningsPage />} />
            <Route path="/attendance" element={<AttendancePage />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/lessonplan/:id" element={<QuestionSlider />} />
            <Route path="/dispute" element={<Dispute />} />
            <Route path="/lesson-plan" element={<LessonPlan />} />
          </Route>
          <Route
            element={
              <div>
                <Auth />
                <Outlet />
              </div>
            }
          >
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
          </Route>
          <Route path="/onboarding" element={<Signup newUser />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
};

export default App;
