import React, { HTMLAttributes } from "react";
import styled from "styled-components";

interface IAvatarProps extends HTMLAttributes<HTMLDivElement> {
  username?: string | null;
  profileImg?: string;
  size?: number;
}

const Avatar: React.FC<IAvatarProps> = ({ username, size, style, profileImg, ...rest }) => {
  return (
    <StyledAvatar style={{ ...style, width: size, height: size }} size={size} {...rest}>
      {profileImg ? (
        <StyledImg
          src={profileImg}
          style={{ width: size ? `${size}px` : "40px", height: size ? `${size}px` : "40px" }}
          alt="avatar"
        />
      ) : (
        <span>{username && username.slice(0, 1)}</span>
      )}
    </StyledAvatar>
  );
};

const StyledAvatar = styled.div<IAvatarProps>`
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--primary);
  display: grid;
  place-items: center;

  > span {
    font-size: ${(props) => (props.size ? `${props.size * 0.4}px` : "16px")};
    color: #fff;
    text-transform: uppercase;
  }
`;

const StyledImg = styled.img`
  aspect-ratio: 1;
  object-fit: cover !important;
  margin: 0 !important;
  border-radius: 50% !important;
  object-position: center;
`;

export default Avatar;
