import React, { useState } from "react";
import styled from "styled-components";

import SectionHeader from "./SectionHeader";
import StatsCard from "./Card/StatsCard";
import dollarImg from "../assets/icons/rocket.png";
import DetailedOverview from "./DetailedOverview";
import { userStore } from "../store/userStore";
import { useGetMonthlyEarnings } from "../hooks/useGetMonthlyEarnings";

const YourEarnings: React.FC = () => {
  const user = userStore((store) => store.user);

  const { data } = useGetMonthlyEarnings();

  return (
    <>
      <div className="pad">
        <SectionHeader title={`Welcome ${user?.displayName}`} description="" />
        <StyledUserSessionStats>
          <StatsCard
            title="Today’s Earnings"
            description={`Number of Completed Classes : ${data?.completedSessionToday ?? 0}`}
            count={data?.todaysEarnings ?? 0}
            image={dollarImg}
          />
          <StatsCard
            title="This Week’s Earnings"
            description={`Number of Completed Classes : ${data?.completedSessionOnThisWeek ?? 0}`}
            count={data?.weeklyEarnings ?? 0}
            image={dollarImg}
          />
          <StatsCard
            title="This Month’s Earnings"
            description={`Number of Completed Classes : ${data?.completedSessionOnThisMonth ?? 0}`}
            count={data?.monthlyEarnings ?? 0}
            image={dollarImg}
          />
        </StyledUserSessionStats>
        {/* <StyledGrid>
          <StyledBlockCard>
            <div className="block-header">
              <h5>Earnings Criteria - 1</h5>
            </div>
            <div className="flex-between mb-8">
              <p>Late Joining Penalty ( 1 session*10)</p>
              <p className="text-error">-Rs 10</p>
            </div>
            <div className="flex-between mb-8">
              <p>Missed Session Penalty (0 session * 70) </p>
              <p className="text-error">-Rs 10</p>
            </div>
            <div className="flex-between">
              <p>Cancelled Session Penalty</p>
              <p className="text-error">-Rs 10</p>
            </div>
          </StyledBlockCard>
          <StyledBlockCard>
            <div className="block-header">
              <h5>Bonus Criteria</h5>
            </div>
            <div className="flex-between mb-8">
              <p>For 60-75 Hrs</p>
              <p className="text-success">Rs 2000</p>
            </div>
            <div className="flex-between mb-8">
              <p>For 75- 100 Hrs</p>
              <p className="text-success">Rs 2000</p>
            </div>
            <div className="flex-between">
              <p>For &gt;100 Hrs</p>
              <p className="text-success">Rs 2000</p>
            </div>
          </StyledBlockCard>
          <StyledBlockCard>
            <div className="flex-between block-header">
              <h5>Duration</h5>
              <h5>Pay Per Session</h5>
            </div>
            <div className="flex-between mb-8">
              <p>30 Min</p>
              <p>Rs 70</p>
            </div>
            <div className="flex-between mb-8">
              <p>45 Min</p>
              <p>Rs 100</p>
            </div>
            <div className="flex-between">
              <p>60 Min</p>
              <p>Rs 120</p>
            </div>
          </StyledBlockCard>
        </StyledGrid> */}
        <DetailedOverview />
      </div>
    </>
  );
};

const StyledUserSessionStats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 400px));
  gap: 15px;
  margin-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  gap: 60px;
  margin-bottom: 30px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
`;

const StyledBlockCard = styled.div`
  border-radius: 8px;
  border: 1px solid #ede7df;
  background: var(--white, #fff);
  padding: 24px;

  .block-header {
    margin-bottom: 24px;

    h5 {
      color: var(--primary-1, #f7941f);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

export default YourEarnings;
