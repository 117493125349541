import { useEffect, useState } from "react";
import { endOfMonth, startOfMonth } from "date-fns";
import dayjs from "dayjs";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

import { userStore } from "../store/userStore";
import { db } from "../utils/firebase";
import { BOOKINGS_COLLECTION_NAME, IBookingSession } from "../constants/types";
import { config } from "../constants/config";
import { useGeneralStore } from "../store/generalStore";

export const useGetMonthlyEarnings = () => {
  const user = userStore((store) => store.user);
  const demoClassTutorFee = useGeneralStore((store) => store.demoClassTutorFee);
  const subscriptionClassTutorFee = useGeneralStore((store) => store.subscriptionClassTutorFee);

  const [earningsData, setEarningsData] = useState<{
    monthlyEarnings: number;
    todaysEarnings: number;
    weeklyEarnings: number;
    completedSessionOnThisMonth: number;
    completedSessionOnThisWeek: number;
    completedSessionToday: number;
  } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
    const q = query(
      colRef,
      where("tutor", "==", user.uid),
      where("endTime", ">=", startOfMonth(new Date())),
      where("endTime", "<=", endOfMonth(new Date())),
      orderBy("endTime", "asc")
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const slots: IBookingSession[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          slots.push({
            id: doc.id,
            ...(data as Omit<IBookingSession, "id">),
          });
        });

        const completedSessionOnThisMonth = slots.filter(
          (f) => f.status === "COMPLETED" || f.status === "MISSED"
        );
        const completedSessionOnThisWeek = slots.filter(
          (f) => f.status === "COMPLETED" && dayjs().isSame(f.startTime.toDate(), "week")
        );
        const completedSessionToday = slots.filter(
          (f) => f.status === "COMPLETED" && dayjs().isSame(f.startTime.toDate(), "day")
        );

        const completedDemoSessionOnThisMonth = completedSessionOnThisMonth.filter((f) =>
          Boolean(f.demoClass)
        ).length;
        const completedSubscriptionSessionOnThisMonth = completedSessionOnThisMonth.filter(
          (f) => !Boolean(f.demoClass)
        ).length;
        const completedDemoSessionOnThisWeek = completedSessionOnThisWeek.filter(
          (f) => !Boolean(f.demoClass)
        ).length;
        const completedSubscriptionSessionOnThisWeek = completedSessionOnThisWeek.filter(
          (f) => !Boolean(f.demoClass)
        ).length;
        const completedDemoSessionToday = completedSessionToday.filter((f) =>
          Boolean(f.demoClass)
        ).length;
        const completedSubscriptionSessionToday = completedSessionToday.filter(
          (f) => !Boolean(f.demoClass)
        ).length;

        setEarningsData({
          completedSessionToday: completedSessionToday.length,
          completedSessionOnThisWeek: completedSessionOnThisWeek.length,
          completedSessionOnThisMonth: completedSessionOnThisWeek.length,
          todaysEarnings:
            completedDemoSessionToday * demoClassTutorFee +
            completedSubscriptionSessionToday * subscriptionClassTutorFee,
          weeklyEarnings:
            completedDemoSessionOnThisWeek * demoClassTutorFee +
            completedSubscriptionSessionOnThisWeek * subscriptionClassTutorFee,
          monthlyEarnings:
            completedDemoSessionOnThisMonth * demoClassTutorFee +
            completedSubscriptionSessionOnThisMonth * subscriptionClassTutorFee,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching upcoming sessions:", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user, demoClassTutorFee, subscriptionClassTutorFee]);

  return { loading, data: earningsData };
};
