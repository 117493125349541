import React from "react";
import styled from "styled-components";

interface IStatsCardProps {
  title: string;
  description?: string;
  count: number | string;
  image: string;
}

const StatsCard: React.FC<IStatsCardProps> = ({ title, description, count, image }) => {
  return (
    <StyledSessionStatsCard>
      <div className="flex-column">
        <h6 className="s-16 mb-8">{title}</h6>
        {description && <p className="s-14 mb-12">{description}</p>}
        <h3>{count}</h3>
      </div>
      <div className="block-right">
        <div className="image">
          <img src={image} alt={title} />
        </div>
      </div>
    </StyledSessionStatsCard>
  );
};

const StyledSessionStatsCard = styled.div`
  border-radius: 8px;
  border: 1px solid #ede7df;
  background: var(--white, #fff);
  padding: 25px;
  display: grid;
  grid-template-columns: 2fr 1fr;

  .block-right {
    display: grid;
    place-items: center;
    border-radius: 50%;

    .image {
      width: 75%;
      aspect-ratio: 1;
      position: relative;
      background-color: #fde9d2;
      border-radius: 50%;
      display: grid;
      place-items: center;

      img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;

    p {
    }
  }
`;

export default StatsCard;
