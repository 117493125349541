import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Circle as CircleIcon, CheckCircle } from "@styled-icons/bootstrap";

import { step2State } from ".";
import {
  FormInput,
  FormInputField,
  FormInputWrapper,
  StyledGenderDiv,
  StyledDropdownWrapper,
  StyledDropdownHeader,
  StyledDropdownList,
  ProfileImageInput,
} from "./Signup.styled";
import { interests, specialization } from "../../../utils/data";
import { Button } from "../../../components";
import { useOnClickOutside } from "usehooks-ts";

interface IStep2Props {
  handleSubmit: (values: typeof step2State) => void;
  setProfileImg: React.Dispatch<
    React.SetStateAction<{
      file: File;
      url: string;
    } | null>
  >;
  profileImg: {
    file: File;
    url: string;
  } | null;
}

const step2ValidationSchema = Yup.object({
  username: Yup.string().required("This field is required"),
  description: Yup.string()
    .min(50, "minimum 50 letters required")
    .required("This field is required"),
  gender: Yup.string().required("This field is required"),
  profileImg: Yup.string().required("This field is required"),
  interests: Yup.array()
    .of(Yup.string())
    .min(1, "This field is required")
    .required("This field is required"),
  specialization: Yup.array()
    .of(Yup.string())
    .min(1, "This field is required")
    .required("This field is required"),
});

const Step2: React.FC<IStep2Props> = ({ handleSubmit, setProfileImg, profileImg }) => {
  const [openInterestsDropdown, setOpenInterestsDropdown] = useState(false);
  const [openSpecializationDropdown, setOpenSpecializationDropdown] = useState(false);
  const interestsRef = useRef(null);
  const specializationRef = useRef(null);

  const handleInterestsClickOutside = () => {
    setOpenInterestsDropdown(false);
  };

  const handlepecializationClickOutside = () => {
    setOpenSpecializationDropdown(false);
  };

  useOnClickOutside(interestsRef, handleInterestsClickOutside);
  useOnClickOutside(specializationRef, handlepecializationClickOutside);

  return (
    <Formik
      initialValues={step2State}
      validationSchema={step2ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, handleBlur, errors, setFieldError }) => (
        <Form>
          <h2 style={{ marginBottom: "10px" }}>Next up, a few details</h2>
          <p style={{ marginBottom: "30px" }}>Tell us a little bit about yourself . </p>
          <FormInputWrapper>
            <div>
              <p className="mb-10">Profile Image</p>
              <ProfileImageInput className="mb-10">
                {profileImg && (
                  <div className="preview-image">
                    <img src={profileImg.url} alt="" />
                  </div>
                )}
                <label htmlFor="profileImg">
                  <span>+</span>
                  <input
                    id="profileImg"
                    name="profileImg"
                    onBlur={handleBlur}
                    type="file"
                    hidden
                    accept=".png,.jpeg,.jpg,.svg,.webp"
                    onChange={(e) => {
                      const files = e.target.files;

                      if (files && files.length) {
                        if (files[0].size <= 5 * 1024 * 1024) {
                          const reader = new FileReader();

                          reader.onload = (upload) => {
                            setProfileImg({ file: files[0], url: upload.target?.result as string });
                            setFieldValue("profileImg", upload.target?.result as string);
                          };

                          reader.readAsDataURL(files[0]);
                        } else {
                          setFieldError(
                            "profileImg",
                            "File size exceeds the 5MB limit. Please select a smaller file."
                          );
                        }
                      }
                    }}
                  />
                </label>
              </ProfileImageInput>
              {errors && errors.profileImg && <div className="error-text">{errors.profileImg}</div>}
            </div>
            <FormInput>
              <label htmlFor="username">Name</label>
              <FormInputField>
                <Field name="username" type="text" placeholder="Enter your full name" />
              </FormInputField>
              <ErrorMessage name="username" component="div" className="error-text" />
            </FormInput>
            <FormInput>
              <label htmlFor="description">Description</label>
              <FormInputField>
                <Field
                  as="textarea"
                  name="description"
                  type="text"
                  placeholder="Tell us about yourself "
                />
              </FormInputField>
              <ErrorMessage name="description" component="div" className="error-text" />
            </FormInput>
            <FormInput>
              <label htmlFor="gender">What’s your gender</label>
              <StyledGenderDiv>
                <button
                  type="button"
                  className={values.gender === "male" ? "active" : ""}
                  onClick={() => setFieldValue("gender", "male")}
                >
                  Male
                </button>
                <button
                  type="button"
                  className={values.gender === "female" ? "active" : ""}
                  onClick={() => setFieldValue("gender", "female")}
                >
                  Female
                </button>
                <button
                  type="button"
                  className={values.gender === "others" ? "active" : ""}
                  onClick={() => setFieldValue("gender", "others")}
                >
                  Others
                </button>
              </StyledGenderDiv>
              <ErrorMessage name="gender" component="div" className="error-text" />
            </FormInput>
            <FormInput>
              <label htmlFor="issues">Choose Your Interests</label>
              <StyledDropdownWrapper ref={interestsRef}>
                <StyledDropdownHeader
                  role="button"
                  onClick={() => setOpenInterestsDropdown((d) => !d)}
                  className={openInterestsDropdown ? "active" : ""}
                >
                  <p className="truncate">
                    {values.interests.length
                      ? values.interests.length === 1
                        ? values.interests.join(",")
                        : `${values.interests[0].slice(0, 35)}... +${values.interests.length - 1}`
                      : "Select your interests"}
                  </p>
                </StyledDropdownHeader>
                {openInterestsDropdown && (
                  <StyledDropdownList>
                    {interests.map((data, index) => (
                      <div
                        key={index.toString()}
                        className={
                          values.interests.some((s) => s.toLowerCase() === data.toLowerCase())
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          if (
                            values.interests.some((s) => s.toLowerCase() === data.toLowerCase())
                          ) {
                            setFieldValue(
                              "interests",
                              values.interests.filter((f) => f.toLowerCase() !== data.toLowerCase())
                            );
                          } else {
                            setFieldValue("interests", [...values.interests, data.toLowerCase()]);
                          }
                          // setOpenIssueDropdown(false);
                        }}
                      >
                        <p>{data}</p>
                        {values.interests.some((s) => s.toLowerCase() === data.toLowerCase()) ? (
                          <CheckCircle color="#F7941F" />
                        ) : (
                          <CircleIcon />
                        )}
                      </div>
                    ))}
                  </StyledDropdownList>
                )}
              </StyledDropdownWrapper>
              <ErrorMessage name="interests" component="div" className="error-text" />
            </FormInput>
            <FormInput>
              <label htmlFor="issues">Choose Your Specialization</label>
              <StyledDropdownWrapper ref={specializationRef}>
                <StyledDropdownHeader
                  role="button"
                  onClick={() => setOpenSpecializationDropdown((d) => !d)}
                  className={openSpecializationDropdown ? "active" : ""}
                >
                  <p className="truncate">
                    {values.specialization.length
                      ? values.specialization.length === 1
                        ? values.specialization.join(",")
                        : `${values.specialization[0].slice(0, 35)}... +${
                            values.specialization.length - 1
                          }`
                      : "Select your specialization"}
                  </p>
                </StyledDropdownHeader>
                {openSpecializationDropdown && (
                  <StyledDropdownList>
                    {specialization.map((data, index) => (
                      <div
                        key={index.toString()}
                        className={
                          values.specialization.some((s) => s.toLowerCase() === data.toLowerCase())
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          if (
                            values.specialization.some(
                              (s) => s.toLowerCase() === data.toLowerCase()
                            )
                          ) {
                            setFieldValue(
                              "specialization",
                              values.specialization.filter(
                                (f) => f.toLowerCase() !== data.toLowerCase()
                              )
                            );
                          } else {
                            setFieldValue("specialization", [
                              ...values.specialization,
                              data.toLowerCase(),
                            ]);
                          }
                          // setOpenIssueDropdown(false);
                        }}
                      >
                        <p>{data}</p>
                        {values.specialization.some(
                          (s) => s.toLowerCase() === data.toLowerCase()
                        ) ? (
                          <CheckCircle color="#F7941F" />
                        ) : (
                          <CircleIcon />
                        )}
                      </div>
                    ))}
                  </StyledDropdownList>
                )}
              </StyledDropdownWrapper>
              <ErrorMessage name="specialization" component="div" className="error-text" />
            </FormInput>
          </FormInputWrapper>
          <Button type="submit">Next</Button>
        </Form>
      )}
    </Formik>
  );
};

export default Step2;
