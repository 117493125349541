import * as Yup from "yup";

export const feedbackValidationSchema = Yup.object({
  status: Yup.string().required("This field is required"),
  fluency: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        rating: Yup.number().min(1, "This field is required").required("This field is required"),
        feedback: Yup.string().required("This field is required"),
      });
    }
    return Yup.object({
      rating: Yup.number(),
      feedback: Yup.string(),
    });
  }),
  pronunciation: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        rating: Yup.number().min(1, "This field is required").required("This field is required"),
        feedback: Yup.string().required("This field is required"),
      });
    }
    return Yup.object({
      rating: Yup.number(),
      feedback: Yup.string(),
    });
  }),
  vocabulary: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        general: Yup.string().required("This field is required"),
        range: Yup.string().required("This field is required"),
        wordChoicePrecision: Yup.string().required("This field is required"),
      });
    }
    return Yup.object({
      general: Yup.string(),
      range: Yup.string(),
      wordChoicePrecision: Yup.string(),
    });
  }),
  grammar: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        tenses: Yup.string().required("This field is required"),
        mostErrors: Yup.string().required("This field is required"),
        articlesAndPrepositions: Yup.string().required("This field is required"),
        subjectVerb: Yup.string().required("This field is required"),
        pronounUsage: Yup.string().required("This field is required"),
        other: Yup.string(),
      });
    }
    return Yup.object({
      tenses: Yup.string(),
      mostErrors: Yup.string(),
      articlesAndPrepositions: Yup.string(),
      subjectVerb: Yup.string(),
      pronounUsage: Yup.string(),
      other: Yup.string(),
    });
  }),
  skills: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        confidence: Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
        passion: Yup.number().min(1, "This field is required").required("This field is required"),
        listeningComprehension: Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
        conversationBuilding: Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
      });
    }
    return Yup.object({
      confidence: Yup.number(),
      passion: Yup.number(),
      listeningComprehension: Yup.number(),
      conversationBuilding: Yup.number(),
    });
  }),
  generalFeedback: Yup.string(),
});

export const feedbackValidationSchemaDemoClass = Yup.object({
  status: Yup.string().required("This field is required"),
  fluency: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        rating: Yup.number().min(1, "This field is required").required("This field is required"),
        feedback: Yup.string().required("This field is required"),
      });
    }
    return Yup.object({
      rating: Yup.number(),
      feedback: Yup.string(),
    });
  }),
  pronunciation: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        rating: Yup.number().min(1, "This field is required").required("This field is required"),
        feedback: Yup.string().required("This field is required"),
      });
    }
    return Yup.object({
      rating: Yup.number(),
      feedback: Yup.string(),
    });
  }),
  vocabulary: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        general: Yup.string().required("This field is required"),
        range: Yup.string().required("This field is required"),
        wordChoicePrecision: Yup.string().required("This field is required"),
      });
    }
    return Yup.object({
      general: Yup.string(),
      range: Yup.string(),
      wordChoicePrecision: Yup.string(),
    });
  }),
  grammar: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        tenses: Yup.string().required("This field is required"),
        mostErrors: Yup.string().required("This field is required"),
        articlesAndPrepositions: Yup.string().required("This field is required"),
        subjectVerb: Yup.string().required("This field is required"),
        pronounUsage: Yup.string().required("This field is required"),
        other: Yup.string(),
      });
    }
    return Yup.object({
      tenses: Yup.string(),
      mostErrors: Yup.string(),
      articlesAndPrepositions: Yup.string(),
      subjectVerb: Yup.string(),
      pronounUsage: Yup.string(),
      other: Yup.string(),
    });
  }),
  skills: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        confidence: Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
        passion: Yup.number().min(1, "This field is required").required("This field is required"),
        listeningComprehension: Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
        conversationBuilding: Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
      });
    }
    return Yup.object({
      confidence: Yup.number(),
      passion: Yup.number(),
      listeningComprehension: Yup.number(),
      conversationBuilding: Yup.number(),
    });
  }),
  suggestions: Yup.object().when("status", (value) => {
    if (value.length && value[0] === "COMPLETED") {
      return Yup.object({
        month: Yup.number().min(1, "This field is required").required("This field is required"),
        sessionsPerWeek: Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
      });
    }
    return Yup.object({
      month: Yup.number(),
      sessionsPerWeek: Yup.number(),
    });
  }),
  generalFeedback: Yup.string(),
});
