import React, { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Set the timezone to IST (Indian Standard Time)
dayjs.tz.setDefault("Asia/Kolkata");

import Button from "../Button";
import { generateTimeIntervalsWithDate } from "../../constants/formatter";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import {
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { TIME_SLOTS_COLLECTION_NAME } from "../../constants/databaseTypes";
import { userStore } from "../../store/userStore";
import { ISlot } from "../../constants/types";
import { useLockedBody } from "usehooks-ts";
import { is } from "date-fns/locale";

interface IBookTimeSlots {
  handleClose: () => void;
  bookedSlots: ISlot[];
  date: Date;
  isDemoClass: boolean;
}

const BookTimeSlots: React.FC<IBookTimeSlots> = ({
  handleClose,
  bookedSlots,
  date,
  isDemoClass,
}) => {
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<
    {
      start: Date;
      end: Date;
    }[]
  >([]);
  const timings = isDemoClass
    ? generateTimeIntervalsWithDate(date, 9, 24, 15)
    : generateTimeIntervalsWithDate(date, 9, 24, 30);
  const user = userStore((store) => store.user);
  const [loading, setLoading] = useState(false);
  useLockedBody(true, "root");

  const handleConfirm = async () => {
    try {
      if (!user) return;

      setLoading(true);

      await Promise.all(
        selectedTimeSlots.map(async (slot) => {
          const formattedDate = dayjs(slot.start).tz("Asia/Kolkata").format();
          const slotName = isDemoClass ? `${formattedDate}-demo` : formattedDate;

          const slotDocRef = doc(db, TIME_SLOTS_COLLECTION_NAME, slotName);

          await runTransaction(db, async (transaction) => {
            const slotDocSnapshot = await transaction.get(slotDocRef);

            if (slotDocSnapshot.exists()) {
              const slotData = slotDocSnapshot.data();

              // Check if the user ID exists in the tutors array
              if (!slotData.tutors.includes(user.uid)) {
                // Push the user ID into the tutors array
                transaction.update(slotDocRef, {
                  tutors: arrayUnion({
                    tutorId: user.uid,
                    isReserved: false,
                    isDemoClass: isDemoClass,
                  }),
                });
              }
            } else {
              // Create a new document if it doesn't exist
              transaction.set(slotDocRef, {
                startTime: Timestamp.fromDate(slot.start),
                endTime: Timestamp.fromDate(slot.end),
                tutors: arrayUnion({
                  tutorId: user.uid,
                  isReserved: false,
                  isDemoClass: isDemoClass,
                }),
              });
            }
          });
        })
      );

      setLoading(false);
      if (handleClose) handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log(bookedSlots);

  const checkTimingSlotDisabled = (d: { start: Date; end: Date }) => {
    const isDisabled = bookedSlots.some((slot) => {
      const slotStart = new Date(slot.startTime);
      let slotEnd = new Date(slot.endTime);
      const isDemoClass = slot.tutors.some((s) => Boolean(s.isDemoClass));

      if (isDemoClass) {
        slotEnd = dayjs(slotStart).add(15, "minutes").toDate();
      } else {
        // Ensure that non-demo classes have a 30-minute duration
        slotEnd = dayjs(slotStart).add(30, "minutes").toDate();
      }

      // Check if the desired slot overlaps with or is fully contained within the booked slot
      const isOverlap =
        (d.start >= slotStart && d.start < slotEnd) ||
        (d.end > slotStart && d.end <= slotEnd) ||
        (d.start <= slotStart && d.end >= slotEnd);

      return isOverlap;
    });

    return isDisabled || dayjs(d.start).isBefore(new Date());
  };

  return (
    <StyldedContainer>
      <StyledDiv>
        <div className="flex-between mb-15">
          <h5>Choose Time Slots</h5>
          <div role="button" className="pointer" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="flex-column">
          <div className="flex">
            {timings.map((d, i) => (
              <Button
                variant="secondary"
                disabled={checkTimingSlotDisabled(d)}
                className={
                  selectedTimeSlots.find((f) => f.start.toISOString() === d.start.toISOString())
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (
                    selectedTimeSlots.find((f) => f.start.toISOString() === d.start.toISOString())
                  ) {
                    setSelectedTimeSlots((t) => [
                      ...t.filter((f) => f.start.toISOString() !== d.start.toISOString()),
                    ]);
                  } else {
                    setSelectedTimeSlots((t) => [...t, d]);
                  }
                }}
                key={i.toString()}
              >
                {dayjs(d.start).format("h:mm A")}
              </Button>
            ))}
          </div>
          <Button disabled={loading || !selectedTimeSlots.length} onClick={() => handleConfirm()}>
            {!selectedTimeSlots.length ? "Choose minimum one slot" : "Confirm"}
          </Button>
        </div>
      </StyledDiv>
    </StyldedContainer>
  );
};

const StyldedContainer = styled.div`
  overflow: hidden;
  border-radius: 20px;
`;

const StyledDiv = styled.div`
  height: 75vh;
  overflow-y: auto;

  .flex-between {
    position: sticky;
    top: 0;
    background-color: var(--white, #fff);
    padding: 20px;
    z-index: 1;

    @media (max-width: 900px) {
      flex-direction: row !important;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 15px;

    .flex {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      button {
        &.active {
          border: 1px solid var(--primary-1, #f7941f);
          background: var(--primary-2, #fff1e0);
        }
      }
    }
  }
`;

export default BookTimeSlots;
