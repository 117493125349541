import React from "react";
import styled from "styled-components";

interface ITabProps {
  lists: string[] | { label: string; value: string }[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const Tab: React.FC<ITabProps> = ({ lists, activeTab, setActiveTab }) => {
  return (
    <StyledTabContainer>
      {lists.map((list, index) =>
        typeof list === "string" ? (
          <button
            key={list}
            onClick={() => {
              setActiveTab(list);
            }}
            className={activeTab === list ? "active" : ""}
          >
            {list}
          </button>
        ) : (
          <button
            key={index}
            onClick={() => {
              setActiveTab(list.value);
            }}
            className={activeTab === list.value ? "active" : ""}
          >
            {list.label}
          </button>
        )
      )}
    </StyledTabContainer>
  );
};

const StyledTabContainer = styled.div`
  display: flex;
  padding: 5.5px 6px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background: var(--gray-1, #ecf0ef);
  width: fit-content;

  button {
    all: unset;
    padding: 10.5px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 200ms linear;
    text-transform: capitalize;

    &.active {
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(72, 72, 72, 0.15);
    }
  }
`;

export default Tab;
