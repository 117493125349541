import { useCallback, useEffect, useState } from "react";
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { endOfMonth, startOfMonth } from "date-fns";
import { userStore } from "../store/userStore";
import { db } from "../utils/firebase";
import {
  BOOKINGS_COLLECTION_NAME,
  IBookingSession,
  TBookingSessionStatus,
  TBookingStatus,
} from "../constants/types";
import { config } from "../constants/config";
import { useGeneralStore } from "../store/generalStore";
import { calculatePenalties } from "../services/tutorService";

export const useGetDetailedOverview = ({
  status,
  selectedDate,
}: {
  status: TBookingSessionStatus;
  selectedDate: Date | null;
}) => {
  const tutor = userStore((store) => store.user);
  const [data, setData] = useState<{
    sessionData: IBookingSession[];
    totalSessions: number;
    totalPenalities: number;
    totalEarnings: number;
    netTotal: number;
    totalDemoSessions: number;
    totalSubscriptionSessions: number;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const demoClassTutorFee = useGeneralStore((store) => store.demoClassTutorFee);
  const subscriptionClassTutorFee = useGeneralStore((store) => store.subscriptionClassTutorFee);

  const handleGetData = useCallback(async () => {
    if (!tutor) return;

    const tempstatus =
      status === "COMPLETED" ? [TBookingStatus.COMPLETED, TBookingStatus.MISSED] : [status];

    const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
    const q = query(
      colRef,
      where("tutor", "==", tutor.uid),
      where("status", "in", [TBookingStatus.COMPLETED, TBookingStatus.MISSED]),
      where("startTime", ">=", startOfMonth(selectedDate || new Date())),
      where("startTime", "<=", endOfMonth(selectedDate || new Date())),
      orderBy("startTime", "asc")
    );

    const datum = await getDocs(q);

    const slots: IBookingSession[] = [];
    datum.forEach((doc) => {
      const data = doc.data();
      slots.push({
        id: doc.id,
        ...(data as Omit<IBookingSession, "id">),
      });
    });

    const totalSessions = slots.filter((f) => f.status === "COMPLETED" || f.status === "MISSED");
    const totalDemoSessions = totalSessions.filter((f) => Boolean(f.demoClass)).length;
    const totalSubscriptionSessions = totalSessions.filter((f) => !Boolean(f.demoClass)).length;
    const totalPenalities = calculatePenalties(totalSessions);
    const totalEarnings =
      totalDemoSessions * demoClassTutorFee + totalSubscriptionSessions * subscriptionClassTutorFee;

    setData({
      sessionData: slots.filter((f) =>
        status === "COMPLETED" ? f.status === status || f.status === "MISSED" : f.status === status
      ),
      totalPenalities,
      totalSessions: totalSessions.length,
      totalDemoSessions: totalDemoSessions,
      totalSubscriptionSessions: totalSubscriptionSessions,
      totalEarnings,
      netTotal: totalEarnings - totalPenalities,
    });
    setLoading(false);
  }, [tutor, selectedDate, status, subscriptionClassTutorFee, demoClassTutorFee]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  return { loading, data: data };
};
