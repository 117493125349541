import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";

import SessionCard from "../../components/SessionCard";
import { BOOKINGS_COLLECTION_NAME, TBookingStatus } from "../../constants/types";
import { IBookingSession } from "../../constants/types";
import { userStore } from "../../store/userStore";
import { db } from "../../utils/firebase";
import NoSession from "../../components/NoSession";
import SessionCardLoader from "../../components/Loader/SessionCardLoader";

const CancelledSessions = () => {
  const user = userStore((store) => store.user);
  const [sessions, setSessions] = useState<IBookingSession[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
    const q = query(
      colRef,
      where("tutor", "==", user.uid),
      where("status", "in", [TBookingStatus.TUTOR_CANCELLED, TBookingStatus.USER_CANCELLED]),
      orderBy("endTime", "desc"),
      limit(20)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const slots: any[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          slots.push({
            id: doc.id,
            ...data,
          });
        });
        setSessions(slots);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching upcoming sessions:", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  if (loading)
    return (
      <StyledCancelledSessionsWrapper style={{ gap: 10 }}>
        <SessionCardLoader count={3} />
      </StyledCancelledSessionsWrapper>
    );

  if (!sessions.length) {
    return <NoSession message="You do not have any cancelled Sessions" />;
  }

  return (
    <StyledCancelledSessionsWrapper>
      {sessions.map((sessionDetails) => (
        <SessionCard key={sessionDetails.id} type="previous" {...sessionDetails} />
      ))}
    </StyledCancelledSessionsWrapper>
  );
};

const StyledCancelledSessionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  max-width: 900px;
  width: 100%;

  > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default CancelledSessions;
