import React, { ReactNode } from "react";
import styled from "styled-components";
import MuiButton, { ButtonProps } from "@mui/material/Button";

interface IButtonProps extends Omit<ButtonProps, "variant"> {
  variant?: "primary" | "secondary" | "error" | "error-filled";
  size?: "small" | "medium" | "large";
  children: ReactNode;
}

const StyledButton = styled(MuiButton)`
  font-family: "Inter";
  border: 1px solid transparent;
  outline: none;
  border-radius: 8px;
  padding: 12px 36px;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: capitalize;
  transition: all 200ms linear;
  font-weight: 500;

  &.btn-primary {
    background: var(--btn-primary);
    opacity: 1;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    border-radius: 8px;
    text-transform: capitalize;

    &:disabled {
      opacity: 0.6;
      color: #fff;
      cursor: no-drop;
    }
  }

  &.btn-secondary {
    border-radius: 8px;
    background-color: #eeeded;
    white-space: nowrap;
    color: black;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.02);
    text-transform: capitalize;
  }

  &.btn-error {
    border-radius: 8px;
    border: 1px solid #cc2c3d;
    color: #cc2c3d;
    background: transparent;
    transition: all 200ms linear;
    text-transform: capitalize;

    &:hover {
      border: 1px solid #cc2c3d;
    }
  }

  &.btn-error-filled {
    border-radius: 8px;
    border: 1px solid rgba(var(--error), 1);
    color: #fff;
    background-color: rgba(var(--error), 1);
    transition: all 200ms linear;
    line-height: 22px;
    text-transform: capitalize;

    &:hover {
      background-color: rgba(var(--error), 0.7);
    }
  }

  &.btn-small {
    padding: 12px 36px;
  }

  &.btn-medium {
    padding: 10px 12px;
  }

  &.btn-large {
    padding: 12px 36px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }
`;

const Button: React.FC<IButtonProps> = ({
  children,
  variant = "primary",
  size = "medium",
  type = "button",
  ...rest
}) => {
  return (
    <StyledButton className={`btn-${variant} btn-${size}`} size={size} type={type} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
