import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { step1State } from ".";
import { FormInput, FormInputField, FormInputWrapper } from "./Signup.styled";
import { Button } from "../../../components";
// import Google from "../../../assets/icons/google.svg";

interface IStep1Props {
  handleSubmit: (values: typeof step1State) => void;
  handleGoogleSignin: () => Promise<void>;
  emailError: string | null;
}

const step1ValidationSchema = Yup.object({
  password: Yup.string().min(8, "minimum 8 characters required").required("This field is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("This field is required"),
});

const Step1: React.FC<IStep1Props> = ({ handleSubmit, handleGoogleSignin, emailError }) => {
  return (
    <>
      <Formik
        initialValues={step1State}
        validationSchema={step1ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <h2 style={{ marginBottom: "10px" }}>Update password!</h2>
            <p className="text-secondary s-16" style={{ marginBottom: "30px" }}>
              {/* Create an account on Talkgram & start your journey fluent english on the go{" "} */}
            </p>
            <FormInputWrapper>
              <FormInput>
                <label htmlFor="password">Password</label>
                <FormInputField>
                  <Field name="password" type="password" placeholder="Enter your password" />
                </FormInputField>
                <ErrorMessage name="password" component="div" className="error-text" />
              </FormInput>
              <FormInput>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <FormInputField>
                  <Field name="confirmPassword" type="password" placeholder="Enter your password" />
                </FormInputField>
                <ErrorMessage name="confirmPassword" component="div" className="error-text" />
              </FormInput>
            </FormInputWrapper>
            <Button size="large" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Updating password" : " Update password"}
            </Button>
          </Form>
        )}
      </Formik>
      {/* <p className="text-secondary" style={{ margin: "30px 0", textAlign: "center" }}>
        or
      </p>
      <div>
        <button className="secondary-auth-btn" onClick={() => handleGoogleSignin()}>
          <img src={Google} alt="google logo" />
          <span>Google</span>
        </button>
      </div> */}
      {/* <p className="text-secondary" style={{ marginTop: "30px", textAlign: "center" }}>
        Already have an account?&nbsp;
        <Link to="/login" className="primary">
          Login here
        </Link>
      </p> */}
    </>
  );
};

export default Step1;
