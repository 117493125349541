import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import autoAnimate from "@formkit/auto-animate";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Modal from "../Modal";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as PlusCircleIcon } from "../../assets/icons/plus-circle.svg";
import BookTimeSlots from "../Modal/BookTimeSlots";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { userStore } from "../../store/userStore";
import { ISlot } from "../../constants/types";
import { TIME_SLOTS_COLLECTION_NAME } from "../../constants/databaseTypes";
import { config } from "../../constants/config";

interface IBookingCard {
  date: Date;
  bookedSlots: ISlot[];
  refetch: () => void;
}

const initialSlotModalState = {
  isOpen: false,
  isDemoClass: false,
};

const BookingCard: React.FC<IBookingCard> = ({ date, bookedSlots, refetch }) => {
  const [slotModal, setSlotModal] = useState(initialSlotModalState);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const parent = useRef(null);
  const user = userStore((store) => store.user);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const reveal = () => setShow(!show);

  const handleClose = () => setSlotModal(initialSlotModalState);

  const handleRemoveSlot = async (slotId: string) => {
    try {
      if (!user) return;

      const isConfirmed = confirm("are you sure, you want to delete?");

      if (!isConfirmed) return;

      setLoading(true);
      const slotDocRef = doc(db, TIME_SLOTS_COLLECTION_NAME, slotId);

      const slotDoc = await getDoc(slotDocRef);

      if (slotDoc.exists()) {
        const data = slotDoc.data();
        const isReserved = data.tutors.find((f: any) => f.tutorId === user.uid);

        if (isReserved) {
          if (isReserved.isReserved) {
            toast.error(
              "Your session has been booked. if you want to cancel the session go to upcoming sessions and cancel it",
              { duration: 5000 }
            );
            return;
          }
        }

        const updatedArray = data.tutors.filter((tutor: any) => tutor.tutorId !== user.uid);

        await updateDoc(slotDocRef, {
          tutors: updatedArray,
        });
      }

      toast.success("Slot removed successfully");
      refetch();
      console.log("User removed from slot successfully");
    } catch (error) {
      console.error("Error removing user from slot:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const redirectToMeet = (id: string) => {
    const queryParams = new URLSearchParams({
      meetingId: "XsVDoTQEYucOaDrcjf3eobUT7f227JScbv8wE4RL7QpLbmTFxXuqse82", //dummy data for testing
      webcamEnabled: "true",
      micEnabled: "true",
    });
    console.log("tutorId", id);
    const sessionLink = `/session/?${queryParams}`;
    navigate(sessionLink);
  };

  return (
    <StyledBookingCardContainer ref={parent}>
      <StyledBookingCard>
        <div className="block-left">
          <div className="flex-column">
            <p className="mb-8">
              <span>{dayjs(date).format("ddd")}</span>
            </p>
            <p>
              <b>{dayjs(date).format("DD MMM")}</b>
            </p>
          </div>
          <div className="add-btn-wrapper">
            <button
              className="add-btn"
              onClick={() =>
                setSlotModal({
                  isOpen: true,
                  isDemoClass: false,
                })
              }
            >
              Add <PlusCircleIcon />
            </button>
            <button
              className="add-btn add-btn-demo"
              onClick={() =>
                setSlotModal({
                  isOpen: true,
                  isDemoClass: true,
                })
              }
            >
              Add Demo Class <PlusCircleIcon />
            </button>
          </div>
        </div>
        <div className="block-right">
          <div role="button" onClick={reveal}>
            <span>View</span>
            <DownArrowIcon />
          </div>
        </div>
        {slotModal && (
          <Modal isOpen={slotModal.isOpen} handleClose={handleClose}>
            <BookTimeSlots
              date={date}
              handleClose={handleClose}
              bookedSlots={bookedSlots}
              isDemoClass={slotModal.isDemoClass}
            />
          </Modal>
        )}
      </StyledBookingCard>
      {show && (
        <StyledViewSection>
          {bookedSlots.length ? (
            bookedSlots.map((slot) => (
              <StyledViewCard key={slot.id}>
                <p>
                  <b>{dayjs(slot.startTime).format("hh:mm a")}</b>
                  {slot.tutors.find((s) => s.tutorId === user?.uid && s.isDemoClass)
                    ? " (Demo Class)"
                    : null}
                </p>
                {dayjs(slot.startTime).diff(new Date(), "hours") >= config.CANCEL_SLOT_DURATION && (
                  <>
                    {/* <button className="icon-btn" disabled={loading}>
                    Join Session
                  </button>{" "} */}
                    <button
                      className="icon-btn"
                      disabled={loading}
                      onClick={() => handleRemoveSlot(slot.id)}
                    >
                      <CloseIcon />
                    </button>
                  </>
                )}
              </StyledViewCard>
            ))
          ) : (
            <StyledNoSlot>
              <p>No slots are booked yet</p>
            </StyledNoSlot>
          )}
        </StyledViewSection>
      )}
    </StyledBookingCardContainer>
  );
};

const StyledBookingCardContainer = styled.div``;

const StyledBookingCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid var(--primary-2-stroke, #ede7df);
  padding: 20px;
  background: #fff;

  .block-left {
    display: flex;
    align-items: center;
    gap: 60px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        color: #2e2d2d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      b {
        color: #2e2d2d;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    @media (max-width: 768px) {
      gap: 20px;
    }

    .add-btn-wrapper {
      display: flex;
      gap: 20px;

      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .add-btn {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 6px 15px;
      border-radius: 30px;
      background: #ecf0ef;
      outline: none;
      border: none;

      color: var(--gray-2, #62635e);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }

    .add-btn-demo {
      font-size: 14px;
    }
  }

  .block-right {
    > div {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
`;

const StyledViewSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--primary-2-stroke, #ede7df);
  background: #fff;
`;

const StyledViewCard = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid var(--primary-2-stroke, #ede7df);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledNoSlot = styled.div`
  padding: 40px 20px;
  text-align: center;
`;

export default BookingCard;
