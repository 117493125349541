import { ITutorFeedback } from "./types";

export const generateTimeIntervals = (startHour: number, endHour: number, range: number) => {
  const intervals = [];

  for (let hour = startHour; hour < endHour; hour++) {
    for (let minute = 0; minute < 60; minute += range) {
      const startTime = formatTime(hour, minute);
      const endTime = formatTime(hour, minute + range);
      intervals.push(`${startTime} - ${endTime}`);
    }
  }

  return intervals;
};

function formatTime(hour: number, minute: number) {
  const period = hour >= 12 ? "pm" : "am";
  const formattedHour = hour > 12 ? hour - 12 : hour;
  const formattedMinute = minute === 0 ? "00" : minute === 60 ? "00" : minute;
  const formattedHourBasedOnMinute = minute === 60 ? formattedHour + 1 : formattedHour;
  return `${formattedHourBasedOnMinute}.${formattedMinute} ${period}`;
}

export const generateTimeIntervalsWithDate = (
  date: Date,
  startHour: number,
  endHour: number,
  range: number
) => {
  const intervals = [];

  for (let hour = startHour; hour < endHour; hour++) {
    for (let minute = 0; minute < 60; minute += range) {
      // Create a new date with the specified date and time
      const startDate = new Date(date);
      startDate.setHours(hour, minute, 0, 0);

      // Create a new date with the specified date and end time
      const endDate = new Date(date);
      endDate.setHours(hour, minute + range, 0, 0);

      intervals.push({
        start: startDate,
        end: endDate,
      });
    }
  }

  return intervals;
};

export const getCurrent7Days = (date?: Date) => {
  const today = date ? new Date(date) : new Date();

  const days = [];
  for (let i = 0; i < 7; i++) {
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + i);
    days.push(nextDay);
  }

  return days;
};

export const calculateTimeDifference = (duration: number) => {
  // Convert the time difference to minutes and seconds
  const minutes = Math.floor(duration / (1000 * 60));
  const seconds = Math.floor((duration % (1000 * 60)) / 1000);

  // Display the time difference
  return `${minutes} mins ${seconds} secs`;
};

export const getTutorFeedbackObj = (tutorFeedback: ITutorFeedback) => {
  const feedback = { ...tutorFeedback };

  if (!tutorFeedback.grammer["pronounUsage"]) {
    tutorFeedback.grammer["pronounUsage"] = "";
  }

  if (!tutorFeedback.grammer["mostErrors"]) {
    tutorFeedback.grammer["mostErrors"] = "";
  }

  if (!tutorFeedback["skills"]) {
    feedback.skills = {
      confidence: 0,
      conversationBuilding: 0,
      listeningComprehension: 0,
      passion: 0,
    };
  }

  if (!tutorFeedback["suggestions"]) {
    feedback.suggestions = {
      month: 0,
      sessionsPerWeek: 0,
    };
  }
  return feedback;
};
