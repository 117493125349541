import { useEffect, useState } from "react";
import { endOfDay, startOfDay } from "date-fns";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

import { userStore } from "../store/userStore";
import { db } from "../utils/firebase";
import { BOOKINGS_COLLECTION_NAME, IBookingSession } from "../constants/types";
import { config } from "../constants/config";
import { useGeneralStore } from "../store/generalStore";

const useGetTodaysEarnings = () => {
  const user = userStore((store) => store.user);
  const demoClassTutorFee = useGeneralStore((store) => store.demoClassTutorFee);
  const subscriptionClassTutorFee = useGeneralStore((store) => store.subscriptionClassTutorFee);
  const [earningsData, setEarningsData] = useState<{
    todayEarnings: number;
    totalSessions: number;
    missedSession: number;
    completedSession: number;
    cancelledSession: number;
  } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    const colRef = collection(db, BOOKINGS_COLLECTION_NAME);
    const q = query(
      colRef,
      where("tutor", "==", user.uid),
      where("startTime", ">=", startOfDay(new Date())),
      where("startTime", "<=", endOfDay(new Date())),
      orderBy("startTime", "asc")
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const slots: IBookingSession[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          slots.push({
            id: doc.id,
            ...(data as Omit<IBookingSession, "id">),
          });
        });

        const totalSessions = slots.filter((f) => f.status !== "USER_CANCELLED").length;
        const missedSession = slots.filter((f) => f.status === "TUTOR_MISSED").length;
        const cancelledSession = slots.filter((f) => f.status === "TUTOR_CANCELLED").length;
        const completedSession = slots.filter((f) => f.status === "COMPLETED");

        const completedDemoSession = completedSession.filter((f) => Boolean(f.demoClass)).length;
        const completedSubscriptionSession = completedSession.filter(
          (f) => !Boolean(f.demoClass)
        ).length;

        setEarningsData({
          totalSessions,
          missedSession,
          completedSession: completedSession.length,
          cancelledSession,
          todayEarnings:
            completedDemoSession * demoClassTutorFee +
            completedSubscriptionSession * subscriptionClassTutorFee,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching upcoming sessions:", error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user, demoClassTutorFee, subscriptionClassTutorFee]);

  return { loading, data: earningsData };
};

export default useGetTodaysEarnings;
