import React from "react";
import styled from "styled-components";

import StatsCard from "../Card/StatsCard";
import CloseIcon from "../../assets/icons/close.svg";
// import Notes from "../Notes";
import { userStore } from "../../store/userStore";
import { config } from "../../constants/config";

const TutorStats: React.FC = () => {
  const userCancelledSession = userStore((store) => store.userCancelledSession);
  return (
    <StyledTutorStatsContainer>
      {/* <Notes
        title="Note"
        description=" We need some time to verify your details. You will be notified via your e-mail and on
              the Talkgram Dahboard after the verification"
      /> */}
      <StatsCard
        title="This Month Cancelled Sessions"
        description=""
        count={`${userCancelledSession} / ${config.MAXIMUM_TUTOR_CANCEL_SESSION}`}
        image={CloseIcon}
      />
      {/* <StatsCard
        title="Today’s Earnings"
        description="Number of Completed Classes : 2"
        count={27}
        image={rocket}
      /> */}
      {/* <StyledGrid>
        <StatsCard title="Today’s Earnings" count={27} image={rocket} />
        <StatsCard title="Today’s Earnings" count={27} image={rocket} />
        <StatsCard title="Today’s Earnings" count={27} image={rocket} />
        <StatsCard title="Today’s Earnings" count={27} image={rocket} />
      </StyledGrid> */}
    </StyledTutorStatsContainer>
  );
};

const StyledTutorStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// const StyledGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 16px;
// `;

export default TutorStats;
