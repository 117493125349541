import { format } from "date-fns";
import dayjs from "dayjs";

export const getLocaleDate = (date: Date) => format(date, "do MMM yyy");

export const customFormat = (date: Date, format: string) => dayjs(date).format(format);

export const slotFormat = (startDate: Date) => dayjs(startDate).format();

export const formatFromToDate = (fromDate: Date, toDate: Date) => {
  const from = customFormat(fromDate, "hh:mm A");
  const to = customFormat(toDate, "hh:mm A");

  return `${from} - ${to}`;
};
