import { IFluencyFeedback, IPronunciationFeedback, TDropdownList } from "./types";

export const bookSessionFilters: TDropdownList[] = [
  {
    name: "Based on Interests",
    value: "Based on Interests",
  },
  {
    name: "Favourite Teacher",
    value: "Favourite Teacher",
  },
  {
    name: "Select Time and Date",
    value: "Select Time and Date",
  },
];

export const sessionFilters: TDropdownList[] = [
  {
    name: "completed sessions",
    value: "completed sessions",
  },
  {
    name: "missed sessions",
    value: "missed sessions",
  },
  {
    name: "cancelled sessions",
    value: "cancelled sessions",
  },
];

export const vocabularyFeedback = ["need to improve", "good", "better", "great"];

export const grammerTitles = [
  { name: "Tenses", value: "tenses" },
  { name: "Articles & Prepositions", value: "articlesAndPrepositions" },
  { name: "Subject Verb Agreement", value: "subjectVerb" },
  { name: "Pronoun usage", value: "pronounUsage" },
];

export const grammerFeedback = [
  "too many errors",
  "noticeable errors",
  "few errors",
  "Rarely noticeable",
  "no errors",
];

export const skillsLable = {
  confidence: "confidence",
  passion: "passion",
  listeningComprehension: "listening comprehension",
  conversationBuilding: "conversation Building",
};

export const fluencyFeedbackItems = [
  IFluencyFeedback.LONG_PAUSES,
  IFluencyFeedback.INCOMPLETE_SENTENCES,
  IFluencyFeedback.FILLER_SOUNDS,
];

export const pronunciationFeedbackItems = [
  {
    name: IPronunciationFeedback.SH_SOUND,
    info: '"Sh" sound: Example: "she" pronounced as "see", "shoes" pronounced as "soos.", etc.,',
  },
  {
    name: IPronunciationFeedback.H_SOUND,
    info: "\"H\" sound: Example: Pronunciation of words like honest, hour, etc., without silencing 'h'. The correct pronunciation is 'onest', 'our'.,",
  },
  {
    name: IPronunciationFeedback.DANDTH_SOUND,
    info: '"D" and "TH" sounds: Example: "The" pronounced as "de" or "that" pronounced as "dat."',
  },
  {
    name: IPronunciationFeedback.FANDP_SOUND,
    info: '"F" and "P" sounds: Example: "Fish" pronounced as "pish" or "phone" pronounced as "pone"',
  },
  {
    name: IPronunciationFeedback.NO_ERRORS,
    info: "",
  },
];

export const vocabulary = {
  vocabularyRange: {
    name: "Vocabulary Range",
    info: "The diversity and quantity of words a person knows and can use effectively",
    options: ["limited", "moderate", "extensive"],
  },
  wordChoicePrecision: {
    name: "Word Choice Precision",
    info: "The ability to select exact and specific words to convey ideas clearly and accurately",
    options: ["appropriate", "occasionally inappropriate", "inappropriate"],
  },
};
